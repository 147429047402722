import { Pagination } from "@mui/material";
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import WestRoundedIcon from '@mui/icons-material/WestRounded';
import React from "react";
import ic_arrow_right from '../icons/ic_arrow_right.svg';
import ic_arrow_left from '../icons/ic_arrow_left.svg';

export default function ListPagination(props: any) {
  const {
    pagination = {
      total_pages: 1,
      total_items: 0,
    },
    page = 1,
    onChange = () => {},
    label = "Items",
  } = props;

  const handlePrevious = () => {
    if (page > 1) {
      onChange(null, page - 1);
    }
  };

  const handleNext = () => {
    if (page < pagination.total_pages) {
      onChange(null, page + 1);
    }
  };

  return (
    <div className="ListPagination">
      <span className="PaginationLabel">
        {pagination.total_items} {label}
      </span>
        <span style={{ marginRight: '8px' }}>
          Page {page} of {pagination.total_pages}
        </span>
        <button 
            onClick={handlePrevious} 
            disabled={page === 1} 
            style={{ background: 'none', border: 'none', cursor: 'pointer', opacity: page === 1 ? 0.5 : 1 }}>
          <img src={ic_arrow_left} alt="Previous" />
        </button>

        <button 
            onClick={handleNext} 
            disabled={page === pagination.total_pages} 
            style={{ background: 'none', border: 'none', cursor: 'pointer', opacity: page === pagination.total_pages ? 0.5 : 1 }}>
          <img src={ic_arrow_right} alt="Next" />
        </button>
    </div>
  );
}
