import { Button, Dialog, FormControlLabel, IconButton, styled, Switch, SwitchProps, TextField, FormControl,
    InputLabel,
    MenuItem,
    Select } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useEffect, useState } from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import DeleteIcon from '@mui/icons-material/Delete';
import { ApiRequest } from "GlobalFunctions";
import ISwitch from "elements/ISwitch";

const defaultValues = {
    logic_id: "",
    logic_name: "",
    conditions: {
        is_active: true,
    },
};

export default function BulkDiscount(props: any) {
    const {
        setLogicType,
        logicValues,
        setLogicvalues
    } = props;

    useEffect(() => {
        console.log("logicValues----", logicValues);
        if (!logicValues.logic_id) {
            console.log("newww");
            setLogicvalues(defaultValues);
        }
    }, []);

    useEffect(() => {
        if (!logicValues.conditions) {
            setLogicvalues({
                ...logicValues,
                conditions: {
                    is_active: true,
                },
            });
        }
    }, [logicValues]);

    return (
        <>
            <div className="boxContainerHeader">
                <div className="boxContainerTitle">
                    <h2 className="h2_title">Bulk & Tiered Discounts</h2>
                </div>
            </div>

            <TextField
                fullWidth
                label="Name"
                value={logicValues.logic_name}
                onChange={(e) => setLogicvalues({ ...logicValues, logic_name: e.target.value })}
            />

            <ISwitch
                label="Turn on Tiered Pricing"
                onChange={(e: any) => {
                    setLogicvalues({
                        ...logicValues,
                        conditions: {
                            ...logicValues.conditions,
                            is_active: e.target.checked
                        }
                    });
                }}
                checked={logicValues.conditions?.is_active || false}
                name="is_active"
            />
        </>
    );
}
