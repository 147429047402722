let ProjectConfigOverride = {};
try {
  ProjectConfigOverride = require('./GlobalOverride').ProjectConfigOverride;
} catch (error) {
  ProjectConfigOverride = {};
}

export const ProjectConfig = {
  api_url: "http://localhost:3001",
  STRIPE_PUBLIC_KEY: "pk_test_51NpZ4yJERu02kDeGMSwl6JVF5BpSByIHzzj3ON1Csk9ekckPViPeR9oLliIp94NjtRjthfzAy00ZAEMCvJeDFHC500aX22iQun",
    WEBFLOW_CLIENT_ID: "04e6713602ea2529e63bf5ac314d691dbe5c3e76fc90ec43f909bbd2e4996797",
    /*============================================
    SQUARE
    =============================================*/
    SQUARE_CLIENT_ID: "sandbox-sq0idb-caUgu2AzZvJM3mQ0b4otBw",
    SQUARE_BASE_URL: "https://connect.squareupsandbox.com",
    SQUARE_REDIRECT_URI: "https://j3q5wm2x-3001.use.devtunnels.ms/square/auth",

  ...ProjectConfigOverride
}
