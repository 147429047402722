import * as React from 'react';
import { Permissions } from 'Permissions';
import MenuButton from 'elements/MenuButton';



import 'assets/css/main.css';


export default function MainMenu(props: any) {

  const { setLoading } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const role_id = Number(localStorage.getItem('role_id'));

  return (
    <div className='menu_button_container'>

      {Permissions.map((permission, index) => {
        if(permission.role_id === role_id){
          return permission.routes.map((route, index) => {
            return (            
                <React.Fragment key={index}>
                  {route.components.map((item: any, index2) => {
                    return <React.Fragment key={index2}> 
                      <MenuButton 
                        label={item.label} 
                        to={item.path} 
                        startIcon={item.icon} 
                        setLoading={setLoading} 
                      />
                    </React.Fragment>
                  })}
                </React.Fragment>
            )
          })
        }
      })}


      

      


      
    </div>
  );
}
