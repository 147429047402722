import { borderBottom, color } from "@mui/system";



export default function InvoicePreview (props: any) {

    const {
        formValues
    } = props;

    const styles = {
        row: {
            borderBottom: "1px solid "+formValues.color+"2b"
        },
        header: {
            backgroundColor: formValues.color+"2b"
        },
        link: {
            color: formValues.color
        }
    }
    

    return (
        <div className="invoice_preview" >
            <div className="invoice-container" style={{ color: formValues.color }}>
                <div className="invoice-header" style={{ backgroundColor: formValues.color+"2b" }}>
                    <div className="invoice-logo-section">
                        
                        <img src={formValues.image} alt="logo" className="invoice-logo" />

                        <div className="invoice-total-date">
                            <span>Total: <b>$0.00</b></span> <br />
                            <span>Thu, 01 Jan 2024</span>
                        </div>
                    </div>

                    <span className="invoice-thankyou-message">
                        Thank You For<br />
                        Your Purchase
                    </span>
                </div>

                <div className="invoice-body">
                    <span className="invoice-receipt-number">Receipt # 0</span>

                    <div className="invoice-products">
                        <div className="invoice-product-item" style={styles.row}>
                            <div className="invoice-product-details">
                                <span className="invoice_label">Product Name Goes Here…</span>
                                <span className="invoice-product-quantity">Qty 0</span>
                            </div>
                            <span className="invoice-product-price">$ 0.00</span>
                        </div>
                    </div>

                   
                    <div className="invoice_row" style={styles.row}>
                        <span className="invoice_label">Total</span>
                        <span className="invoice_value">$ 0.00</span>
                    </div>

                    <div className="invoice_row" style={styles.row}>
                        <span className="invoice_label">Delivery</span>
                        <span className="invoice_value">$ 0.00</span>
                    </div>

                    <div className="invoice_row" style={styles.row}>
                        <span className="invoice_label">Tax</span>
                        <span className="invoice_value">$ 0.00</span>
                    </div>

                    <div className="invoice_row" style={styles.row}>
                        <span className="invoice_label">Amount Paid</span>
                        <span className="invoice_value">$ 0.00</span>
                    </div>

                    <span className="invoice-contact-info">
                        Questions? Contact us at <a href="mailto:johnny@choquercreative.com" className="invoice-contact-email" style={styles.link}>{formValues.email}</a>
                    </span>
                </div>
            </div>
        </div>

    )
}