import axios from 'axios';
import { useEffect, useState } from 'react';
import Box from 'elements/Box';
import { Button, Chip, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Pagination } from '@mui/material';
import { useSnackbar } from 'notistack';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { ProjectConfig } from 'Global';
import AlertDialog from 'elements/AlertDialog';
import UsersEditor from './UsersEditor';
import EmptyElement from 'elements/EmptyElement';

import 'assets/css/main.css';

export default function UsersList(props: any) {
  const { company_id = null, is_popup = false } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { setLoading } = props;

  useEffect(() => {
    setLoading(false);
  }, []);

  const [user_list, setUserList] = useState<any[]>([]);
  const [total_pages, setTotalPages] = useState(0);
  const currentPage = parseInt(localStorage.getItem('page') || '1');
  const [page, setPage] = useState(currentPage);
  const [openEditor, setOpenEditor] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [activeItem, setActiveItem] = useState<any>(null);
  const [openAlert, setOpenAlert] = useState(false);

  useEffect(() => {
    setLoading(true);
    loadList();
  }, [page]);

  /*=========================================================
  LOAD LIST
  =========================================================*/
  const loadList = () => {
    setLoading(true);

    axios
      .get(ProjectConfig.api_url + '/users/admin_list', {
        headers: {
          ltkn: localStorage.getItem('ltkn'),
        },
        params: {
          page: page,
          role_id: 2,
          company_id: company_id,
        },
      })
      .then((response) => {
        setUserList(response.data.list);
        setTotalPages(response.data.total_pages);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });

    localStorage.setItem('page', page.toString());
  };

  /*=========================================================
  DELETE ITEM
  =========================================================*/
  const handleDelete = (user_id: number) => {
    setActiveItem(user_id);
    setOpenAlert(true);
  };

  const deleteItem = () => {
    const body = {
      user_id: activeItem,
    };

    axios
      .post(ProjectConfig.api_url + '/users/deleteItem', body)
      .then((response) => {
        if (response.data.status === 'success') {
          enqueueSnackbar('User Deleted', { variant: 'success' });
          loadList();
        }
      })
      .catch((err) => {
        console.log(err.data);
      })
      .finally(() => {
        setOpenAlert(false);
      });
  };

  /*=========================================================
  HANDLE MENU OPEN
  =========================================================*/
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, item: any) => {
    setMenuAnchor(event.currentTarget);
    setActiveItem(item);
  };

  /*=========================================================
  HANDLE MENU CLOSE
  =========================================================*/
  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  return (
    <div id="UsersList" className="ModuleContainer">
      <div className="ModuleWrapper">
        <div className="boxContainerHeader">
          <div className="boxContainerTitle">
            <h1>Admin Roles</h1>
          </div>

          <div className="boxContainerActions">
            <Button
              variant="outlined"
              color="info"
              onClick={() => {
                setActiveItem(null);
                setOpenEditor(true);
              }}
              startIcon={<AddIcon />}
              className="btnList"
            >
              New Admin
            </Button>
          </div>
        </div>

        <div id="UsersListIn">
          <div>
            {user_list.length !== 0 ? (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: '#a8a8a8', borderBottom: 'none' }}>User</TableCell>
                      <TableCell sx={{ color: '#a8a8a8', borderBottom: 'none' }}>Email</TableCell>
                      <TableCell sx={{ color: '#a8a8a8', borderBottom: 'none' }}>Status</TableCell>
                      <TableCell sx={{ color: '#a8a8a8', borderBottom: 'none' }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {user_list.map((user, i) => (
                      <TableRow
                        key={i}
                        hover
                        sx={{
                          '&:hover': {
                            backgroundColor: '#ecebff !important',
                          },
                        }}
                      >
                        <TableCell>{user.name} {user.last_name}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>
                          <Chip label={user.is_active ? 'Active' : 'Inactive'} color={user.is_active ? 'primary' : 'error'} variant="outlined" size="small" />
                        </TableCell>
                        <TableCell align="right">
                          <IconButton color="primary" onClick={(event) => handleMenuOpen(event, user)}>
                            <MoreHorizIcon />
                          </IconButton>
                          <Menu
                            anchorEl={menuAnchor}
                            open={Boolean(menuAnchor && activeItem && activeItem.user_id === user.user_id)}
                            onClose={handleMenuClose}
                          >
                            <MenuItem
                              onClick={() => {
                                setActiveItem(user);
                                setOpenEditor(true);
                                handleMenuClose();
                              }}
                            >
                              <ModeEditIcon fontSize="small" /> Edit
                            </MenuItem>
                            <MenuItem onClick={() => handleDelete(user.user_id)}>
                              <DeleteOutlineOutlinedIcon fontSize="small" /> Remove
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <div className="emptyList">
                <EmptyElement title="No users" />
              </div>
            )}
            {total_pages > 1 && (
              <Pagination
                count={total_pages}
                page={page}
                onChange={(event, value) => {
                  setPage(value);
                }}
                color="primary"
                style={{ marginTop: '20px', alignSelf: 'center' }}
              />
            )}
          </div>
        </div>
      </div>

      <AlertDialog
        open={openAlert}
        setOpen={setOpenAlert}
        subtitle="¿Está seguro que desea eliminar este usuario?"
        send={deleteItem}
      />

      <UsersEditor
        setLoading={setLoading}
        openEditor={openEditor}
        setOpenEditor={setOpenEditor}
        user_id={activeItem?.user_id}
        loadList={loadList}
        company_id={company_id}
      />
    </div>
  );
}
