import { createTheme, ThemeProvider } from '@mui/material/styles';

export const theme = createTheme({
  typography: {
    fontFamily: ['PT Sans', 'sans-serif'].join(','),
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 300,
    fontWeightMedium: 300,
    fontWeightBold: 700,
  },
  palette: {
    primary: {
      light: '#7963ff',
      main: '#7963ff',
      dark: '#7963ff',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ECEBFF',
      main: '#ECEBFF',
      dark: '#ECEBFF',
      contrastText: '#1F1752',
    },
    info: {
      light: '#424242',
      main: '#424242',
      dark: '#424242',
      contrastText: '#fff',
    },
    error: {
      main: '#FAA8A8',
      contrastText: '#fff',
    },
    background: {
      default: '#fafafa',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 4,
          padding: '10px 20px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: "#ecebff",
            borderWidth: 1,
            borderRadius: ".5rem",
          },
          '&:hover fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: "#7963ff",
          },
          '& .MuiInputBase-root, & .MuiInputLabel-root': {
            fontSize: '30px',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-input": {
            fontSize: '30px',
          },
        },
      },
    },
    // MuiTableRow: {
    //   styleOverrides: {
    //     root: {
    //       '&.MuiTableRow-root:hover': {
    //         backgroundColor: '#ecebff !important',
    //       },
    //       '&.MuiTableRow-head:hover': {
    //         backgroundColor: 'transparent !important',
    //       },
    //     },
    //   },
    // },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: '.5rem', // Personalizar el borde del menú desplegable
        },
      },
    },
  },
});

