import * as React from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ProjectConfig } from 'Global';
import { List } from 'devextreme-react';
import { Button, LinearProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import EmptyElement from './EmptyElement';
import { ApiRequest } from 'GlobalFunctions';

 
export default function AutocompleteField(props: any){

    const {
        endpoint,
        label,
        value,
        onChange,
        fullwidth = false
    } = props;

    

    const [list, setList] = useState<any>([]);


    useEffect(() => {
      getList();
    }, []);


    /*=========================================================
    GET LIST
    =========================================================*/
    const getList = async () => {
      await ApiRequest({
        url: endpoint,
        method: "get",
        headers: {
            ltkn: localStorage.getItem('ltkn')
        },
        query: {
            full_list: true,
        }, 
        setResponse: (response: any) => {
            setList(response.list);
        },
      });
    }


    const [itemName , setItemName] = useState(null);
    /*=========================================================
    RETURN
    =========================================================*/
    
    useEffect(() => {
      if (list && (value && value!=0)) 
      { 
        // item_name = list.find(obj => {
        //   return obj.warehouse_id === parseInt(value); 
        // })
        
        let item = list.find((obj: any) => obj.value === parseInt(value));
        console.log("ITEMxxxxxxxxx", item);
        if(item)
        {
          
          setItemName(item);
        }
      } 
    } , [list, value]);
    

    return (
        <>
            {list && list.length > 0 && (

                <Autocomplete
                    value={itemName}
                    onChange={(event: any, newValue: any) => {
                        onChange(newValue);
                    }}
                    id="autocomplete"
                    options={list}
                    fullWidth = {fullwidth}
                    getOptionLabel={(option: any) => option.label}
                    renderOption={(props, option) => <li className="flex_select" {...props}>
                      <div className="flex_option_1">{option.label}</div>
                    </li>}
                    renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
                />
            )}

           
        </>
        
    );
}

