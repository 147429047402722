import { Button, Dialog, FormControlLabel, styled, Switch, SwitchProps, TextField, Radio, RadioGroup, InputLabel } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import { ProjectConfig } from 'Global';
import { useSnackbar, withSnackbar } from "notistack";
import ISwitch from "elements/ISwitch";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PercentIcon from '@mui/icons-material/Percent';

import { ApiRequest } from "GlobalFunctions";
import ic_logic from 'icons/ic_logic.svg';

export default function CouponsEditor(props : any) {

  /*=============================================
  VARIABLES FROM PROPS
  ============================================= */
  const {
    setLoading, 
    openEditor, 
    setOpenEditor, 
    coupon_id,
    loadList
  } = props;
  console.log("coupon_id", coupon_id);

  const [logicType, setLogicType] = useState<any>({});

  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */
  
  const defaultFormValues = {
    coupon_id: coupon_id,
    coupon_name: "",
    coupon_code: "",
    coupon_type: "discount",  // Inicializado en "discount"
    amount: "",
    redeem_by_date: false,
    redeem_by_quantity: false,
    start_date: "",
    end_date: "",
    max_redemptions: "",
  };

  const [formValues, setFormValues] = useState<any>(defaultFormValues);
  
  /*=======================================
  GET DETAILS
  =======================================*/
  useEffect(() => {
    if (coupon_id) {
      getDetails();
    } else {
      setFormValues(defaultFormValues); // Reiniciar formulario al abrir para agregar
    }
  } , [openEditor]);

  const getDetails = async () => { 
    await ApiRequest({
      method: 'GET',
      url: '/coupons/details',
      query: {
        coupon_id: coupon_id
      },
      setResponse: (response: any) => {
        setFormValues(response);
      }
    });
  }

  /*=======================================
  SUBMIT FUNCTION
  =======================================*/
  const handleSubmit = async () => {  
    console.log("formValues", formValues);
    setLoading(true);

    await ApiRequest({
      method: 'POST',
      url: '/coupons/addedit',
      body: formValues,
      setResponse: (response: any) => {
        if(response.status === "success"){
          enqueueSnackbar('Success', { variant: 'success' });
          setOpenEditor(false);
          loadList();
          
        }else{
          enqueueSnackbar(response.message, { variant: 'error' });
        }
      }
    });
    setLoading(false);
  };

  /*=======================================
  EFFECT TO CLEAR DATES WHEN REDEEM_BY_DATE IS UNCHECKED
  =======================================*/
  useEffect(() => {
    if (!formValues.redeem_by_date) {
      setFormValues((prevValues:any) => ({
        ...prevValues,
        start_date: "",
        end_date: "",
      }));
    }
  }, [formValues.redeem_by_date]);

  /*=======================================
  EFFECT TO CLEAR MAX REDEMPTIONS WHEN REDEEM_BY_QUANTITY IS UNCHECKED
  =======================================*/
  useEffect(() => {
    if (!formValues.redeem_by_quantity) {
      setFormValues((prevValues:any) => ({
        ...prevValues,
        max_redemptions: "",
      }));
    }
  }, [formValues.redeem_by_quantity]);

  const handleClose = () => {
    setOpenEditor(false);
    setFormValues(defaultFormValues); // Reiniciar el formulario al cerrar
  };

  return (
      <>
        <Dialog 
          open={openEditor}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          PaperProps={{ sx: { borderRadius: "20px", minWidth:"750px" } }}
        >
          <div className="DialogContainer">
            <div className="dialog_container_title">
                <h2 className="dialog_title"><img src={ic_logic} alt="Logic Icon" />
                  {coupon_id ? "Edit Coupon" : "Add Coupon"}
                </h2>
            </div>
            
            <div className="dialogFormContainer">
              <div className="input_group">
                <TextField
                    fullWidth
                    label="Name"
                    variant="outlined"
                    value={formValues.coupon_name}
                    onChange={(e) => setFormValues({...formValues, coupon_name: e.target.value})}
                />
                <TextField
                    fullWidth
                    label="Code"
                    variant="outlined"
                    value={formValues.coupon_code}
                    onChange={(e) => setFormValues({...formValues, coupon_code: e.target.value})}
                />

                <InputLabel>Discount Type</InputLabel>
                <RadioGroup
                    row
                    aria-label="coupon_type"
                    name="coupon_type"
                    color="primary"
                    className="radio_group"
                    value={formValues.coupon_type}
                    onChange={(e) => setFormValues({...formValues, coupon_type: e.target.value})}
                    style={{ display: "flex", flexDirection: "column" }}
                >
                    <FormControlLabel style={{color: "#424242"}} value="discount" control={<Radio />} label="Percentage Discount" />
                    <FormControlLabel style={{color: "#424242"}} value="price" control={<Radio />} label="Fixed Amount Discount" />
                </RadioGroup>

                <TextField
                  fullWidth
                  label="Amount"
                  type="number"
                  variant="outlined"
                  value={formValues.amount}
                  onChange={(e) => setFormValues({...formValues, amount: e.target.value})}
                  InputProps={{
                    startAdornment: formValues.coupon_type !== "discount" ? <AttachMoneyIcon /> : null,
                    endAdornment: formValues.coupon_type === "discount" ? <PercentIcon /> : null
                  }}
                />

                <ISwitch
                    label="Redeem by Date"
                    checked={formValues.redeem_by_date}
                    onChange={(e:any) => setFormValues({...formValues, redeem_by_date: e.target.checked})}
                />

                {formValues.redeem_by_date && (
                  <>
                    <TextField
                      fullWidth
                      label="Start Date"
                      variant="outlined"
                      value={formValues.start_date}
                      onChange={(e) => setFormValues({...formValues, start_date: e.target.value})}
                      type="date"
                      InputLabelProps={{
                          shrink: true,
                      }}
                      
                  />
                  <TextField
                      fullWidth
                      label="End Date"
                      variant="outlined"
                      value={formValues.end_date}
                      onChange={(e) => setFormValues({...formValues, end_date: e.target.value})}
                      type="date"
                      InputLabelProps={{
                          shrink: true,
                      }}
                  />
                  </>
                )}

                <ISwitch
                    label="Redeem by Quantity"
                    checked={formValues.redeem_by_quantity}
                    onChange={(e:any) => setFormValues({...formValues, redeem_by_quantity: e.target.checked})}
                />

                {formValues.redeem_by_quantity && (
                  <TextField
                      fullWidth
                      label="Max Redemptions"
                      variant="outlined"
                      value={formValues.max_redemptions}
                      onChange={(e) => setFormValues({...formValues, max_redemptions: e.target.value})}
                  />
                )}
              </div>

            </div>
          </div>

          <div className="dialogButtons">

              <Button
                  onClick={handleSubmit}
                  variant="contained"
                  className="button_1"
              >
                {coupon_id ? "Edit Coupon" : "Add Coupon"}
              </Button>

            <Button
                onClick={handleClose}
                variant="contained"
                color="secondary"
                className="button_secondary"
            >
              Cancel
            </Button>
          </div>
        </Dialog>
      </>
  );
}
