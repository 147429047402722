import axios from 'axios';
import { useEffect, useState } from 'react';
import { Button, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useSnackbar } from 'notistack';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { ProjectConfig } from 'Global';
import AlertDialog from 'elements/AlertDialog';
import PlanEditor from './PlanEditor';
import ListPagination from 'elements/ListPagination';
import 'assets/css/main.css'; 

export default function PlanList(props: any) {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoading } = props;

  useEffect(() => {
    setLoading(false);
  }, []);

  const [list, setList] = useState<any[]>([]);
  const [pagination, setPagination] = useState({
    total_pages: 1,
    total_items: 0,
  });

  const currentPage = parseInt(localStorage.getItem('page') || '1');
  const [page, setPage] = useState(currentPage);

  const [openEditor, setOpenEditor] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [activeItem, setActiveItem] = useState<any>(null);
  const [openAlert, setOpenAlert] = useState(false);

  useEffect(() => {
    setLoading(true);
    loadList();
  }, [page]);

  /*=========================================================
  LOAD LIST
  =========================================================*/
  const loadList = () => {
    setLoading(true);
    axios
      .get(ProjectConfig.api_url + '/plans/list', {
        params: {
          page: page,
          role_id: 1,
        },
      })
      .then((response) => {
        setList(response.data.list);
        setPagination(response.data.pagination);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });

    localStorage.setItem('page', page.toString());
  };

  /*=========================================================
  DELETE ITEM
  =========================================================*/
  const handleDelete = (plan_id: number) => {
    setActiveItem(plan_id);
    setOpenAlert(true);
  };

  const deleteItem = () => {
    const body = {
      plan_id: activeItem,
    };

    axios
      .post(ProjectConfig.api_url + '/plans/deleteItem', body)
      .then((response) => {
        if (response.data.status === 'success') {
          enqueueSnackbar('Package Deleted', { variant: 'success' });
          loadList();
        }
      })
      .catch((err) => {
        console.log(err.data);
      })
      .finally(() => {
        setOpenAlert(false);
      });
  };

  /*=========================================================
  HANDLE MENU OPEN
  =========================================================*/
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, item: any) => {
    setMenuAnchor(event.currentTarget);
    setActiveItem(item);
  };

  /*=========================================================
  HANDLE MENU CLOSE
  =========================================================*/
  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  return (
    <div id="PlanList" className="ModuleContainer">
      <div className="ModuleWrapper">
        <div className="boxContainerHeader">
          <div className="boxContainerTitle">
            <h1>Packages</h1>
          </div>
          <div className="boxContainerActions">
            <Button
              variant="outlined"
              color="info"
              onClick={() => {
                setActiveItem(null);
                setOpenEditor(true);
              }}
              startIcon={<AddIcon />}
              className="btnList"
            >
              Add Package
            </Button>
          </div>
        </div>

        <div id="PlanListIn">
          <div>
            <TableContainer sx={{ width: '100%' }}>
              <Table sx={{ width: '100%' }}>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: '#a8a8a8', borderBottom: 'none' }}>Plan</TableCell>
                    <TableCell sx={{ color: '#a8a8a8', borderBottom: 'none' }}>ID</TableCell>
                    <TableCell sx={{ color: '#a8a8a8', borderBottom: 'none' }}>Users</TableCell>
                    <TableCell sx={{ color: '#a8a8a8', borderBottom: 'none' }}>Monthly price</TableCell>
                    <TableCell sx={{ color: '#a8a8a8', borderBottom: 'none' }}>Annual price</TableCell>
                    <TableCell sx={{ color: '#a8a8a8', borderBottom: 'none' }}>Status</TableCell>
                    <TableCell sx={{ color: '#a8a8a8', borderBottom: 'none' }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list.map((item, i) => (
                    <TableRow
                      key={i}
                      hover
                      role="checkbox"
                      sx={{
                        '&:hover': {
                          backgroundColor: '#ecebff !important', // Color del hover
                        },
                      }}
                    >
                      <TableCell>{item.plan_name}</TableCell>
                      <TableCell>{item.plan_id}</TableCell>
                      <TableCell>{item.active_subscriptions}</TableCell>
                      <TableCell>${item.price_monthly}</TableCell>
                      <TableCell>${item.price_yearly}</TableCell>
                      <TableCell style={{ color: item.status === 'Active' ? '#0A7715' : '#CCCCCC' }}>
                        {item.status}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton aria-label="edit" color="primary" onClick={(event) => handleMenuOpen(event, item)}>
                          <MoreHorizIcon />
                        </IconButton>
                        <Menu
                          anchorEl={menuAnchor}
                          open={Boolean(menuAnchor && activeItem && activeItem.plan_id === item.plan_id)}
                          onClose={handleMenuClose}
                        >
                          <MenuItem
                            onClick={() => {
                              setOpenEditor(true);
                              handleMenuClose();
                            }}
                          >
                            <ModeEditIcon fontSize="small" /> Edit
                          </MenuItem>
                          <MenuItem onClick={() => handleDelete(item.plan_id)}>
                            <DeleteOutlineOutlinedIcon fontSize="small" /> Remove
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <ListPagination
              pagination={pagination}
              page={page}
              onChange={(event: any, value: any) => {
                setPage(value);
              }}
              label="Packages"
              style={{ marginTop: '20px', alignSelf: 'center' }}
            />
          </div>
        </div>
      </div>

      <AlertDialog
        open={openAlert}
        setOpen={setOpenAlert}
        subtitle="Are you sure you want to delete this item?"
        send={deleteItem}
      />

      <PlanEditor
        setLoading={setLoading}
        openEditor={openEditor}
        setOpenEditor={setOpenEditor}
        plan_id={activeItem?.plan_id}
        loadList={loadList}
      />
    </div>
  );
}
