import { Button, Dialog, FormControlLabel, styled, Switch, SwitchProps, TextField } from "@mui/material"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default function LogicMenu(props: any) {

    const {
        setLogicType
    } = props;

    return (
        <>


            <Button
                variant="text"
                color="primary"
                className="logic_button"
                fullWidth
                onClick={() => {
                    setLogicType({
                    logic_type: "shipping",
                    logic_type_id: 1
                    })
                    }
                }
                endIcon={<ArrowForwardIosIcon sx={{width: "14px"}} />}
                >
                <div className="logic_button_text">
                    <span className="login_button_title">Shipping logic</span>
                    <span className="login_button_description">Set custom shipping rates and conditions</span>
                </div>
                </Button>

                <Button
                variant="text"
                color="primary"
                className="logic_button"
                fullWidth
                onClick={() => {
                    setLogicType({
                    logic_type: "purchase_order",
                    logic_type_id: 2
                    })
                    }
                }
                endIcon={<ArrowForwardIosIcon sx={{width: "14px"}} />}
                >
                <div className="logic_button_text">
                    <span className="login_button_title">Purchase Order</span>
                    <span className="login_button_description">Enable purchase orders for wholesale clients</span>
                </div>
                </Button>

                <Button
                variant="text"
                color="primary"
                className="logic_button"
                fullWidth
                onClick={() => {
                    setLogicType({
                    logic_type: "bulk_discount",
                    logic_type_id: 3
                    })
                    }
                }
                endIcon={<ArrowForwardIosIcon sx={{width: "14px"}} />}
                >
                <div className="logic_button_text">
                    <span className="login_button_title">Bulk & Tiered Discounts</span>
                    <span className="login_button_description">Implement volume-based discount strategies</span>
                </div>
                </Button>

                <Button
                variant="text"
                color="primary"
                className="logic_button"
                fullWidth
                onClick={() => {
                    setLogicType({
                    logic_type: "hide_variables",
                    logic_type_id: 4
                    })
                    }
                }
                endIcon={<ArrowForwardIosIcon sx={{width: "14px"}} />}
                >
                <div className="logic_button_text">
                    <span className="login_button_title">Hide Variables</span>
                    <span className="login_button_description">Manage visible elements in the cart with ease</span>
                </div>
                </Button>

                <Button
                variant="text"
                color="primary"
                className="logic_button"
                fullWidth
                onClick={() => {
                    setLogicType({
                    logic_type: "build_bundle",
                    logic_type_id: 5
                    })
                    }
                }
                endIcon={<ArrowForwardIosIcon sx={{width: "14px"}} />}
                >
                <div className="logic_button_text">
                    <span className="login_button_title">Build A Bundle</span>
                    <span className="login_button_description">Offer special pricing on bundled items</span>
                </div>
                </Button>

                {/* <Button
                variant="text"
                color="primary"
                className="logic_button"
                fullWidth
                onClick={() => {
                    setLogicType({
                        logic_type: "custom",
                        logic_type_id: 6
                    })
                    }
                }
                endIcon={<ArrowForwardIosIcon sx={{width: "14px"}} />}
                >
                <div className="logic_button_text">
                    <span className="login_button_title">Custom</span>
                    <span className="login_button_description">Unlock advanced features through custom code integration</span>
                </div>
                </Button> */}
            </>
        )
}