import React from 'react';
import axios from 'axios';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import {
    TextField,
    Link,
    Button
} from '@mui/material';

import Box from 'elements/Box';
import { ProjectConfig } from 'Global';

export default function FormLogin( props: any ) {

    const {
        logo,
        setLoading,
        setState,
        email,
        setEmail,
        loginData,
        setLoginData,
        loadingButton,
        setLoadingButton,
        setOpenRegister,
        sign_in,
    } = props;
    
    /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    /*============================================= */

    /*================================================
    SIGN IN (SUBMIT)
    ================================================*/
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        setLoadingButton(true);
        
        sign_in();

    
    };

    /*================================================
    ================================================*/


    return(
        <Box id='loginBox' className='intent-auth_component'>
            <img className='loginLogo' src={logo}></img>

            <h1 className="intent-auth_heading text-color-darkpurple">Log into my account</h1>
            
            <form onSubmit={handleSubmit} className='form'>
            <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                value={loginData.email}
                onChange={(e) => {
                    setLoginData({ ...loginData, email: e.target.value });
                    setEmail(e.target.value);
                }}
                type="text"
                autoComplete="email"
                autoFocus
            />
            <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={loginData.password}
                onChange={(e) => setLoginData({ ...loginData, password: e.target.value })}
            />
            <LoadingButton
                size="small"
                type="submit"
                loading={loadingButton}
                fullWidth
                variant="contained"
                sx={{ fontSize: '13px', padding: '10px 0px'}}
            >
                Log In
            </LoadingButton>


            <div className='intent-auth_links'>
                <Link 
                    component="button"
                    variant="body2"
                    onClick={()=>{
                        setState('signup')
                    }}
                >
                    Don’t Have An Account?
                </Link>



                <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                        setState('forgotPass')
                    }}
                >
                Forgot Password? 
                </Link>
            </div>
            </form>
        </Box>
    );
}