import { Button, Dialog, FormControlLabel, styled, Switch, SwitchProps, TextField } from "@mui/material"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default function Custom(props: any) {

    const {
        setLogicType
    } = props;

    return (
        <>
            <div className="boxContainerHeader">
                <div className="boxContainerTitle">
                    <h2 className="h2_title">Custom</h2>
                </div>
            </div>
        </>
    )
}