import { Button, Dialog, FormControl, styled, Switch, SwitchProps, TextField } from "@mui/material"
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import {ProjectConfig} from 'Global';
import { useSnackbar, withSnackbar } from "notistack";

import ISwitch from "elements/ISwitch";


//TODAY DATE FORMATED MM/DD/YYYY
const today = new Date('2014-08-18T21:11:54')


export default function CompanyEditor(props : any) {

  const {
    setLoading,
    openEditor,
    setOpenEditor,
    company_id,
    loadList
  } = props;


  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */



  // company_id	company_name	email	nit	address	city_id	manager_name	manager_phone		
  const navigate = useNavigate();
  const defaultValues: any = {
    company_id: company_id? company_id : "null",
    company_name: "",
    email: "",
    nit: "",
    address: "",
    city_id: null,
    manager_name: "",
    manager_phone: "",
    plan_id: null,
    restrictions: {}
  };
  
  const [formValues, setFormValues] = useState(defaultValues)



  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };


  /*=======================================
  SUBMIT FORM
  =======================================*/
  const handleSubmit = () => {
    setLoading(true)

    const body = new FormData();
    body.append("formValues", JSON.stringify(formValues));
    axios.post(ProjectConfig.api_url+"/v2/companies/addedit", body,
      {
        headers: {
          // 
        },
      })
      .then((response) => {
        console.log(response.data);

        if(response.data.status === "success"){
          enqueueSnackbar('Success', { variant: 'success' });
          setOpenEditor(false)
          loadList();
        }else{
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
          
      })
      .catch((err) => {
        console.log(err.response.data);
      })
      .finally(() => {
        setLoading(false)
      });
  };



  /**/

  useEffect(() => {
    if (company_id) {
      
      axios.get(ProjectConfig.api_url + "/companies/details", {
          params: {
            company_id: company_id,
          },
        })
        .then((response) => {
          console.log("formValues res", response.data)
          setFormValues(response.data)
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    }
    else{
      setFormValues(defaultValues);
    }
  }, [company_id])


  return (
      <>
        <Dialog 
          open={openEditor}
          onClose={() => setOpenEditor(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="DialogContainer" style={{maxWidth:'502px'}}>
              <div className="ModuleWrapper">
                  <div className="boxContainerHeader">
                      <div className="boxContainerTitle">
                          <h2 className="h2_title">Empresa</h2>
                          <span className="h2_subtitle">Editar</span>
                      </div>

                      
                  </div>

                  <div id="userForm">
                    <div className="userForm_right">
                        <div className='input_group'>
                            <h2>Información empresa</h2>
                            <div className='input_group'>

                                <div className='input_row'>

                                <TextField 
                                  id="company_name"
                                  name="company_name"
                                  label="Nombre empresa"
                                  type="text"
                                  required
                                  fullWidth
                                  value={formValues.company_name}
                                  onChange={handleInputChange}
                                />
                                

                                <TextField
                                  id="nit"
                                  name="nit"
                                  label="NIT"
                                  type="text"
                                  required
                                  fullWidth
                                  value={formValues.nit}
                                  onChange={handleInputChange}
                              />
                              

                              </div>

                              <TextField
                                  id="email"
                                  name="email"
                                  label="Email"
                                  type="text"
                                  required
                                  fullWidth
                                  value={formValues.email}
                                  onChange={handleInputChange}
                                />

                              
                            </div>

                            <div className='input_group'>
                              <h2>Persona de contacto</h2>
                              <div className="input_row">
                                <TextField
                                  id="manager_name"
                                  name="manager_name"
                                  label="Nombre"
                                  type="text"
                                  required
                                  fullWidth
                                  value={formValues.manager_name}
                                  onChange={handleInputChange}
                                />

                                <TextField
                                  id="manager_phone"
                                  name="manager_phone"
                                  label="Teléfono"
                                  type="text"
                                  required
                                  fullWidth
                                  value={formValues.manager_phone}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>


                            <div className='input_group'>
                              <h2>Dirección</h2>
                              <div className="input_row">
                                <TextField
                                  id="address"
                                  name="address"
                                  label="Dirección"
                                  type="text"
                                  required
                                  fullWidth
                                  value={formValues.address}
                                  onChange={handleInputChange}
                                />
                              </div>

                              

                            </div>

                            <div className='input_group'>
                              <h2>Restricciones</h2>
                              <div className="input_row">
                                {/* restrictions.kilos */}
                                <ISwitch
                                  label="Kilos"
                                  checked={formValues.restrictions?.kilos !== undefined ? formValues.restrictions.kilos : false}
                                  onChange={(e: any) => {
                                    setFormValues({
                                      ...formValues,
                                      restrictions: {
                                        ...formValues.restrictions,
                                        kilos: e.target.checked,
                                      },
                                    });
                                  }}
                                />

                                {/* restrictions.square_meter */}
                                <ISwitch
                                  label="M2"
                                  checked={formValues.restrictions?.square_meter !== undefined ? formValues.restrictions.square_meter : false}
                                  onChange={(e: any) => {
                                    setFormValues({
                                      ...formValues,
                                      restrictions: {
                                        ...formValues.restrictions,
                                        square_meter: e.target.checked,
                                      },
                                    });
                                  }}
                                />

                                

                              </div>
                            </div>

                        </div>
                    </div>
                </div>
              </div>
          </div>

          <div className="dialogButtons">
            <Button
                onClick={() => setOpenEditor(false)}
                variant="outlined"
                className="button_2"
            >
              Cancelar
            </Button>

            <Button onClick={handleSubmit} variant="contained" className="button_1">Guardar</Button>
          </div>
        </Dialog>
      </>
  );
}
