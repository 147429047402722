import { Button, Dialog, FormControlLabel, IconButton, styled, Switch, SwitchProps, TextField, FormControl,
    InputLabel,
    MenuItem,
    Select, } from "@mui/material"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useEffect, useState } from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import DeleteIcon from '@mui/icons-material/Delete';
import { ApiRequest } from "GlobalFunctions";
import ISwitch from "elements/ISwitch";

const defaultValues = {
    logic_id: "",
    logic_name: "",
    conditions: {
        variables: [
            {
                variable: "",
                bag: "show",
                cart: "show",
                checkout: "show",
            }
        ],
    },
}

export default function HideVariables(props: any) {

    const {
        setLogicType,
        logicValues,
        setLogicvalues
    } = props;

    useEffect(() => {
        console.log("logicValues----", logicValues);
        if (!logicValues.logic_id) {
            console.log("newww");
            setLogicvalues(defaultValues);
        }
    }, []);

    useEffect(() => {
        if (!logicValues.conditions || !Array.isArray(logicValues.conditions.variables)) {
            setLogicvalues({
                ...logicValues,
                conditions: {
                    variables: [
                        {
                            variable: "",
                            bag: "show",
                            cart: "show",
                            checkout: "show",
                        }
                    ],
                },
            });
        }
    }, [logicValues]);

    if (!logicValues.conditions || !Array.isArray(logicValues.conditions.variables)) {
        return null;
    }
    
    return (
        <>
            <div className="boxContainerHeader">
                <div className="boxContainerTitle">
                    <h2 className="h2_title">Hide variables</h2>
                </div>
            </div>

            <TextField
                fullWidth
                label="Name"
                value={logicValues.logic_name}
                onChange={(e) => setLogicvalues({...logicValues, logic_name: e.target.value})}
            />

            <div className="input_group">
                <div className="dialog_section_title">
                    <div>
                        <h2 className="h2_section_title">Variables</h2>
                        <span className="subitle">Add more variables by clicking ‘Add Variable”</span>
                    </div>
                </div>
                
                {logicValues.conditions.variables.map((variable: any, variableIndex: number) => (
                    <div key={variableIndex} className="input_group">
                        <div className="input_row">

                            <TextField
                                fullWidth
                                label="Variable"
                                value={variable.variable}
                                onChange={(e) => {
                                    let conditions = {...logicValues.conditions};
                                    conditions.variables[variableIndex].variable = e.target.value;
                                    setLogicvalues({...logicValues, conditions: conditions});
                                }}
                            />
                            
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Bag</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="rule"
                                    value={variable.bag}
                                    label="Bag"
                                    name="bag"
                                    onChange={(e) => {
                                        let conditions = {...logicValues.conditions};
                                        conditions.variables[variableIndex].bag = e.target.value;
                                        setLogicvalues({...logicValues, conditions: conditions});
                                    }}
                                >
                                    <MenuItem value={"hide"}>Hide</MenuItem>
                                    <MenuItem value={"show"}>Show</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Cart</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="rule"
                                    value={variable.cart}
                                    label="Cart"
                                    name="cart"
                                    onChange={(e) => {
                                        let conditions = {...logicValues.conditions};
                                        conditions.variables[variableIndex].cart = e.target.value;
                                        setLogicvalues({...logicValues, conditions: conditions});
                                    }}
                                >
                                    <MenuItem value={"hide"}>Hide</MenuItem>
                                    <MenuItem value={"show"}>Show</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Checkout</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="rule"
                                    value={variable.checkout}
                                    label="Checkout"
                                    name="checkout"
                                    onChange={(e) => {
                                        let conditions = {...logicValues.conditions};
                                        conditions.variables[variableIndex].checkout = e.target.value;
                                        setLogicvalues({...logicValues, conditions: conditions});
                                    }}
                                >
                                    <MenuItem value={"hide"}>Hide</MenuItem>
                                    <MenuItem value={"show"}>Show</MenuItem>
                                </Select>
                            </FormControl>

                            <IconButton 
                                aria-label="delete" 
                                disabled={logicValues.conditions.variables.length === 1}
                                onClick={() => {
                                    let conditions = {...logicValues.conditions};
                                    conditions.variables.splice(variableIndex, 1);
                                    setLogicvalues({...logicValues, conditions: conditions});
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    </div>
                ))}

                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        let conditions = {...logicValues.conditions};
                        conditions.variables.push({variable: "", bag: "show", cart: "show", checkout: "show"});
                        setLogicvalues({...logicValues, conditions: conditions});
                    }}
                >
                    Add Variable
                </Button>
            </div>
        </>
    )
}
