import { Button, FormControlLabel, Radio, RadioGroup } from "@mui/material";

export default function PlansButtons(props: any) {

    const {
        plans,
        billingCycle,
        selectedPlan,
        handlePlanChange,
        activePlan,
        setOpenDialog
    } = props;

    /*============================
    FORMAT PRICE
    ============================*/
    const formatPrice = (price: number) => {
        if (Number(price) === 0) {
            return <div className="plan_price_flex">
                <span className="plan_price-highlight">Free</span>
            </div>
        }
        
        return (
            <div className="plan_price_flex">
                <span className="plan_price-highlight">${price}</span>
                <div className="plan_price_currency">
                    <span className="plan_price-superscript">USD</span>
                    <span className="plan_price_cycle">/ mo</span>
                </div>
            </div>
        );
    };
    
    
    return (
        <div id='plan_buttons' className="plans_container">
            {plans.map((plan: any, index: number) => {
                const monthlyPrice = billingCycle === 'yearly' 
                    ? plan.yearly.price / 12 
                    : plan.monthly.price;
                
                    const type_of_change = billingCycle === 'yearly' ? plan.yearly.type_of_change : plan.monthly.type_of_change


                return (
                    <div 
                        key={index} 
                        className={`plan_item ${Number(activePlan) === Number(plan.plan_id) ? 'is_featured' : ''}`}
                    >
                        <div  className="plan_container">
                            <span className="plan_name">{plan.plan_name}</span>
                            <span className="plan_s_description">{plan.short_description}</span>
                            <span className="plan_description">{plan.description}</span>
                            {formatPrice(monthlyPrice)}
                           
                            <Button 
                                variant="contained"
                                onClick={() => {
                                    
                                    if (type_of_change !== 'Current') {
                                        setOpenDialog(true)
                                        handlePlanChange(plan.plan_id)}
                                    }
                                }
                                style={{backgroundColor: type_of_change !== 'Current' ? '#1f1752' : '#1f1752'}}
                            >
                                {type_of_change}
                            </Button>
                        </div>
                    </div>
                );
            })}
        </div>
    )
}





