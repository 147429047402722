import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

export default function PlansRadio(props: any) {

    const {
        plans,
        billingCycle,
        selectedPlan,
        handlePlanChange
    } = props;

    /*============================
    FORMAT PRICE
    ============================*/
    const formatPrice = (price: number) => {
        let priceText = "Free";
        if (Number(price) > 0) {
            priceText = `$${price} / Month`
        }
        return priceText;
    };
    
    return (
        <div className="plans_container">
            <RadioGroup 
                className="plans_group" 
                value={selectedPlan} 
                onChange={(e) => handlePlanChange(e.target.value)}
            >
                {plans.map((plan: any, index: number) => {
                    const monthlyPrice = billingCycle === 'yearly' 
                        ? plan.yearly.price / 12 
                        : plan.monthly.price;
                    
                    return (
                        <div 
                            key={index} 
                            className={`plan_item ${Number(selectedPlan) === Number(plan.plan_id) ? 'checked' : ''}`}
                        >
                            <FormControlLabel
                                value={plan.plan_id}
                                control={
                                    <Radio 
                                        sx={{
                                            backgroundColor: "white",
                                            color: "#ecebff",
                                            "&.Mui-checked": {
                                                backgroundColor: "white"
                                            },
                                            "&:hover": {
                                                backgroundColor: "white"
                                            }
                                        }}
                                        className="plan_radio" 
                                    />
                                }
                                label={
                                    <div className="plan_label">
                                        <span className="plan_name">{plan.plan_name}</span>
                                        <span className="plan_price">{formatPrice(monthlyPrice)}</span>
                                        <span className="plan_type_change">
                                            {billingCycle === 'yearly' ? plan.yearly.type_of_change : plan.monthly.type_of_change}
                                        </span>
                                    </div>
                                }
                                className={`plan_form_control ${selectedPlan === plan.plan_id ? 'plan_selected' : ''}`}
                            />
                        </div>
                    );
                })}
            </RadioGroup>
        </div>
    )
}