import { Button, TextField } from "@mui/material";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { ApiRequest } from "GlobalFunctions";
import { MdEdit } from "react-icons/md";
import { IoSaveOutline } from "react-icons/io5";

export default function CreditCard(params: any) {
  const { payment_data } = params;
  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar } = useSnackbar();

    /*=====================
  LOADING STATE
  ======================*/
  const [loading, setLoading] = useState(false);
  const [activeCardEditor, setActiveCardEditor] = useState(false);

  /*=====================
  STATE HANDLING
  ======================*/
  const [cardValues, setCardValues] = useState({
    name_on_card: '',
    card_number: '',
    card_expiration: '',
    cvv: ''
  });

  const [displayData, setDisplayData] = useState(payment_data || {});

  useEffect(() => {
    if (!activeCardEditor) {
      setDisplayData(payment_data || {});
    }
  }, [payment_data, activeCardEditor]);

  /*=====================
  ERROR HANDLING
  ======================*/
  const [errors, setErrors] = useState({
    card_expiration: false,
  });



  /*=====================
  SUBMIT
  ======================*/
  const handleSubmit = () => {
    if (!cardValues.name_on_card || cardValues.name_on_card.length < 2) {
      enqueueSnackbar('Please enter a valid name', { variant: 'error' });
      return;
    }

    if (!cardValues.card_number || cardValues.card_number.replace(/\s/g, '').length < 16) {
      enqueueSnackbar('Please enter a valid card number', { variant: 'error' });
      return;
    }

    if (errors.card_expiration) {
      enqueueSnackbar('Please enter a valid expiration date', { variant: 'error' });
      return;
    }

    if (!cardValues.cvv || cardValues.cvv.length < 3) {
      enqueueSnackbar('Please enter a valid CVV', { variant: 'error' });
      return;
    }

    setLoading(true);

    const body = {
      ...cardValues,
      store_id: localStorage.getItem('store_id')
    };

    ApiRequest({
      method: 'POST',
      url: '/payments/updateCreditCard',
      body: body,
      setResponse: (response: any) => {
        if (response.status === "success") {
          enqueueSnackbar(response.message, { variant: 'success' });
          // Actualizar displayData con los nuevos valores
          setDisplayData({
            ...cardValues,
            card_number: '************' + cardValues.card_number.replace(/\s/g, '').slice(-4),
            cvv: '***',
          });
          setActiveCardEditor(false);
          // Limpiar los valores del formulario
          setCardValues({
            name_on_card: '',
            card_number: '',
            card_expiration: '',
            cvv: ''
          });
        } else {
          enqueueSnackbar(response.message, { variant: 'error' });
        }
        setLoading(false);
      },
      onError: () => {
        enqueueSnackbar('Error updating payment information', { variant: 'error' });
        setLoading(false);
      }
    });
  };

  return (
    <div className="account_section">
      <div className="section_header">
        <h2>Payment Data</h2>
        <div className="section_actions">
          {activeCardEditor && (
            <Button
              variant="outlined"
              onClick={() => {
                setActiveCardEditor(false);
                // Restaurar los datos originales
                setCardValues({
                  name_on_card: '',
                  card_number: '',
                  card_expiration: '',
                  cvv: ''
                });
              }}
              color="info"
              className="btnList2"
            >
              Cancel
            </Button>
          )}
          <Button
            variant="outlined"
            onClick={() => {
              if (!activeCardEditor) {
                // Entrar en modo de edición y limpiar los campos
                setCardValues({
                  name_on_card: '',
                  card_number: '',
                  card_expiration: '',
                  cvv: ''
                });
                setActiveCardEditor(true);
              } else {
                handleSubmit();
              }
            }}
            color="info"
            className="btnList2"
            startIcon={activeCardEditor ? <IoSaveOutline /> : <MdEdit />}
            disabled={loading}
          >
            {loading ? 'Updating...' : activeCardEditor ? 'Save' : 'Edit'}
          </Button>
        </div>
      </div>

      {/* Formulario de datos de la tarjeta */}
      <div>
        <div className="account_row">
          <span className="account_label">Name On Card</span>
          <TextField
            id="name_on_card"
            name="name_on_card"
            type="text"
            size="small"
            required
            fullWidth
            value={activeCardEditor ? cardValues.name_on_card : displayData.name_on_card || ''}
            inputProps={{ readOnly: !activeCardEditor }}
            onChange={(e) => setCardValues({ ...cardValues, name_on_card: e.target.value })}
            className={!activeCardEditor ? 'input_editor input_readonly' : 'input_editor'}
          />
        </div>

        <div className="account_row">
          <span className="account_label">Card Number</span>
          <TextField
            id="card_number"
            name="card_number"
            type="text"
            size="small"
            required
            fullWidth
            value={activeCardEditor ? cardValues.card_number : displayData.card_number || ''}
            onChange={(e) => {
              const formattedValue = e.target.value
                .replace(/\D/g, '')
                .replace(/(.{4})/g, '$1 ')
                .trim()
                .substring(0, 19);
              setCardValues({ ...cardValues, card_number: formattedValue });
            }}
            inputProps={{ maxLength: 19, readOnly: !activeCardEditor }}
            className={!activeCardEditor ? 'input_editor input_readonly' : 'input_editor'}
          />
        </div>

        <div className="account_row">
          <span className="account_label">Card Expiration</span>
          <TextField
            id="card_expiration"
            name="card_expiration"
            type="text"
            size="small"
            required
            fullWidth
            value={activeCardEditor ? cardValues.card_expiration : displayData.card_expiration || ''}
            inputProps={{ readOnly: !activeCardEditor }}
            onChange={(e) => {
              let cleanedValue = e.target.value.replace(/\D/g, '');
              if (cleanedValue.length > 2) {
                cleanedValue = cleanedValue.substring(0, 2) + '/' + cleanedValue.substring(2);
              }
              setCardValues({ ...cardValues, card_expiration: cleanedValue.substring(0, 5) });

              const [month, year] = cleanedValue.split('/').map(Number);
              const currentDate = new Date();
              const currentMonth = currentDate.getMonth() + 1;
              const currentYear = currentDate.getFullYear() % 100;

              let isValid = true;
              if (
                !month ||
                !year ||
                month > 12 ||
                month < 1 ||
                year < currentYear ||
                (year === currentYear && month < currentMonth)
              ) {
                isValid = false;
              }
              setErrors({ ...errors, card_expiration: !isValid });
            }}
            error={errors.card_expiration}
            helperText={errors.card_expiration ? "Invalid date MM/YY" : ""}
            className={!activeCardEditor ? 'input_editor input_readonly' : 'input_editor'}
          />
        </div>

        <div className="account_row">
          <span className="account_label">CVV</span>
          <TextField
            id="cvv"
            name="cvv"
            type="text"
            size="small"
            required
            fullWidth
            value={activeCardEditor ? cardValues.cvv : '***'}
            inputProps={{ maxLength: 4, readOnly: !activeCardEditor }}
            onChange={(e) => setCardValues({ ...cardValues, cvv: e.target.value })}
            className={!activeCardEditor ? 'input_editor input_readonly' : 'input_editor'}
          />
        </div>
      </div>
    </div>
  );
}
