
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { padding } from "@mui/system";
import { useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import { IoSaveOutline } from "react-icons/io5";
import { ApiRequest } from "GlobalFunctions";
import { set } from "lodash";
import { useSnackbar, withSnackbar } from "notistack";
import { Link } from "react-router-dom";
import PaymentMethodForm from "./Components/PaymentMethodForm";
import Payment from "../Payment/Payment";
import AlertDialog from "elements/AlertDialog";
import ImageUploader from "elements/ImageUploader";
import CreditCard from "./Components/CreditCard";

export default function AccountEditor(props:any) {

    /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    /*============================================= */


    const {setLoading, GetUserInfo} = props;
    const [accountValues, setAccountValues] = useState<any>({
        company_name: '',
        contact_person_name: '',
        contact_person_lastname: '',
        email: '',
        password: '',
        company_id: '',
        store_id: '',
        country_id: '1',
        currency: 'USD',
        logo: '',
        payment_data:{}
    });

    const [activeAccountEditor, setActiveAccountEditor] = useState(false);
    

    useEffect (() => {
        getAccountDetails();
        setLoading(false);
    }, []);


    const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
        const { name, value } = e.target;
        setAccountValues({
          ...accountValues,
          [name]: value,
        });
      };

    
    /*=============================================
    GET ACCOUNT DETAILS
    =============================================*/
    const getAccountDetails = async () => {

        await getCountries();
        await ApiRequest({
            method: 'GET',
            url: '/stores/getStoreAccount',
            setResponse: (response: any) => {
                setAccountValues(response.data);
            }
        });
    }


    /*=============================================
    UPDATE ACCOUNT
    =============================================*/
    const updateAccount = () => {

        const body = new FormData();
        body.append('formValues', JSON.stringify(accountValues));

        if (files) {
            body.append("logo", files);
        }

        ApiRequest({
            method: 'POST',
            url: '/stores/updateStoreAccount',
            formData: true,
            body: body,
            setResponse: (response: any) => {
                enqueueSnackbar('Account updated', { variant: 'success' });
                setActiveAccountEditor(false);
                GetUserInfo();
            },
        });
    }

    /*=============================================
    GET COUNTRIES
    =============================================*/
    const [countries, setCountries] = useState([]);
    const getCountries = async () => {
        await ApiRequest({
            url: '/countries/getCountries',
            method: 'GET',
            setResponse: (response: any) => {
                setCountries(response);
            }
        });
    }

    const validateEmail = (email: string) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }



    const [openDeleteAlert, setOpenDeleteAlert] = useState(false);

    const deleteStore = async () => {
        setLoading(true);
        await ApiRequest({
            method: 'POST',
            url: '/stores/deleteStore',
            setResponse: (response: any) => {
                if(response.default_store_id) {
                    localStorage.setItem('store_id', response.default_store_id);
                } 
                else {
                    localStorage.removeItem('store_id');
                }
                enqueueSnackbar('Store deleted', { variant: 'success' });
                window.location.href = '/';
            },
        });
        
    }

    const [files, setFiles] = useState<any>([]);

    return (
        <>
        <div className='ModuleContainer'>
            <div className="ModuleHeader">
                <div className="ModuleTitle">
                    <h1>Account</h1>
                </div>
                <div className="ModuleActions">

                </div>
            </div>

            <div id='account_editor' className='ModuleContent'>
                
                <div className="account_section">
                    <div className="section_header">
                        <h2>Profile</h2>
                        <div className="section_actions">

                            {activeAccountEditor && (
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        setActiveAccountEditor(false);
                                    }}
                                    color="info"
                                    className="btnList2"
                                >
                                    Cancel
                                </Button>
                            )}
                            
                            
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    !activeAccountEditor? setActiveAccountEditor(true) :
                                    updateAccount();
                                }}
                                color="info"
                                className="btnList2"
                                startIcon={activeAccountEditor ? <IoSaveOutline /> : <MdEdit />}
                            >
                                {activeAccountEditor ? 'Save' : 'Edit'}
                            </Button>
                        </div>
                    </div>

                    <div className="account_row">
                        <span className="account_label">Logo</span>
                            <ImageUploader 
                                className="account_logo"
                                image={accountValues.logo} 
                                setImage={(newImage) => setAccountValues({ ...accountValues, logo: newImage })}
                                setFiles={setFiles} 
                                disabled={!activeAccountEditor}
                            />
                    </div>

                    <div className="account_row">
                        <span className="account_label">Company Name</span>
                        <TextField
                            name="company_name"
                            value={accountValues.company_name}
                            fullWidth
                            size="small"
                            inputProps={{ readOnly: !activeAccountEditor }}
                            className={!activeAccountEditor ? 'input_editor input_readonly' : 'input_editor'}

                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="account_row">
                        <span className="account_label">Contact person</span>
                        <div className="input_row">
                            <TextField
                                name="contact_person_name"
                                value={
                                    !activeAccountEditor? accountValues.contact_person_name + ' ' + accountValues.contact_person_lastname :
                                    accountValues.contact_person_name
                                }
                                fullWidth
                                size="small"
                                inputProps={{ readOnly: !activeAccountEditor }}
                                className={!activeAccountEditor ? 'input_editor input_readonly' : 'input_editor'}
                                onChange={handleInputChange}
                            />
                            
                            {activeAccountEditor && (
                                <TextField
                                    name="contact_person_lastname"
                                    value={accountValues.contact_person_lastname}
                                    fullWidth
                                    size="small"
                                    inputProps={{ readOnly: !activeAccountEditor }}
                                    className={!activeAccountEditor ? 'input_editor input_readonly' : 'input_editor'}
                                    onChange={handleInputChange}
                                />
                            )}
                        </div>
                    </div>
                    <div className="account_row">
                        <span className="account_label">Email</span>
                        <TextField
                            name="email"
                            value={accountValues.email}
                            fullWidth
                            size="small"
                            inputProps={{ readOnly: !activeAccountEditor }}
                            className={!activeAccountEditor ? 'input_editor input_readonly' : 'input_editor'}

                            onChange={handleInputChange}
                            error={!validateEmail(accountValues.email)}
                        />
                    </div>
                    <div className="account_row">
                        <span className="account_label">Password</span>
                        <TextField
                            name="password"
                            value={accountValues.password}
                            fullWidth
                            size="small"
                            type={activeAccountEditor ? 'text' : 'password'}
                            inputProps={{ readOnly: !activeAccountEditor }}
                            className={!activeAccountEditor ? 'input_editor input_readonly' : 'input_editor'}

                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="account_row">
                        <span className="account_label">Company #</span>
                        <TextField
                            name="company_id"
                            value={accountValues.company_id}
                            fullWidth
                            size="small"
                            inputProps={{ readOnly: !activeAccountEditor }}
                            className={!activeAccountEditor ? 'input_editor input_readonly' : 'input_editor'}

                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="account_row">
                        <span className="account_label">Country</span>
                        <FormControl fullWidth>
                            <Select
                                id="country_select"
                                size="small"
                                value={accountValues.country_id}
                                name="country"
                                inputProps={{ readOnly: !activeAccountEditor }}
                                className={!activeAccountEditor ? 'input_editor input_readonly' : 'input_editor'}
                                onChange={(e) => {
                                    setAccountValues({...accountValues, country_id: e.target.value })
                                }}
                            >
                                {countries.map((country: any, index: number) => {
                                    return (
                                        <MenuItem key={index} value={country.country_id}>{country.country_name}</MenuItem>
                                    ) 
                                })}
                                
                            </Select>
                        </FormControl>
                    </div>

                    <div className="account_row">
                        <span className="account_label">Currency</span>
                        <FormControl fullWidth>
                            <Select
                                id="currency_select"
                                size="small"
                                value={accountValues.currency}
                                name="currency"
                                inputProps={{ readOnly: !activeAccountEditor }}
                                className={!activeAccountEditor ? 'input_editor input_readonly' : 'input_editor'}
                                onChange={(e) => {
                                    setAccountValues({...accountValues, currency: e.target.value })
                                }}
                            >
                                <MenuItem value="USD">USD</MenuItem>
                                <MenuItem value="CAD">CAD</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
                
                <div className="account_section">
                    <div className="section_header">
                        <h2>Connect Webflow Website</h2>
                        <div className="section_actions">
                        </div>
                    </div>

                    <span className="account_description_text">Add a new Webflow project from the drop down in the top right</span>

                    <div className="account_row">
                        <span className="account_label">Webflow account linked</span>
                        <Button
                            variant="contained"
                            component={Link}
                            to="/webflow"
                        >
                            Reconnect Webflow Account
                        </Button>
                    </div>
                </div>


                 {/* Plan */}
                 <div className="account_section">
                    <div className="section_header">
                        <h2>Plan</h2>
                        <div className="section_actions">
                        </div>
                    </div>
                    <Payment setLoading={setLoading} style="account" />
                </div>

                <CreditCard payment_data={accountValues.payment_data} />


                {/* PAYMENTS */}
                <div className="account_section">
                    <div className="section_header">
                        <h2>Payment Gateway</h2>
                        <div className="section_actions">
                        </div>
                    </div>
                        <PaymentMethodForm />
                </div>

                {/* PLAN ACTIONS */}
                <div className="account_section">
                    <div className="section_header">
                        <h2>Plan Actions</h2>
                        <div className="section_actions">
                        </div>
                    </div>
                    <div className="account_row">
                        <span className="account_label">Delete</span>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => setOpenDeleteAlert(true)}
                            
                        >
                            Delete Account
                        </Button>
                    </div>
                </div>


                
                
            </div>
        </div>

        <AlertDialog
            open={openDeleteAlert}
            title="Delete Account"
            subtitle="Are you sure you want to delete this account? This action cannot be undone."
            setOpen={setOpenDeleteAlert}
            send={deleteStore}
        />
        </>
    )
}