import { useState, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import { TextField } from "@mui/material";
import { ApiRequest } from "GlobalFunctions";
import query from "devextreme/data/query";
import { useSnackbar, withSnackbar } from 'notistack';

export default function User(props: any) {
    const { formValues, setFormValues, setStep} = props;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();


    const [passwordRequirements, setPasswordRequirements] = useState([
        { text: 'A minimum of 10 characters', valid: false },
        { text: 'at least one number', valid: false },
        { text: 'at least one lowercase letter', valid: false },
        { text: 'at least one uppercase letter', valid: false }
    ]);

    useEffect(() => {
        const { password } = formValues.user;

        setPasswordRequirements([
            { text: 'A minimum of 10 characters', valid: password.length >= 10 },
            { text: 'at least one number', valid: /\d/.test(password) },
            { text: 'at least one lowercase letter', valid: /[a-z]/.test(password) },
            { text: 'at least one uppercase letter', valid: /[A-Z]/.test(password) }
        ]);
    }, [formValues.user.password]);


    const validateEmail = async () => {
        
        const { email } = formValues.user;
        let valid = true;
        await ApiRequest({
            method: 'get',
            url: '/users/validateEmail',
            query: { email },
            setResponse: (response:any) => {
                if(response.status !== "success"){
                    enqueueSnackbar(response.message, { variant: 'error' });
                    valid = false;
                }
            }
        });

        return valid;

    }


    const validatePassword = async () => {
        let valid = true;
        passwordRequirements.forEach(requirement => {
            if(!requirement.valid) valid = false;
        });

        if(!valid){
            enqueueSnackbar('Password Is Invalid', { variant: 'error' });
        }

        return valid;
    }

    const submit = async () => {
        const emailValid = await validateEmail();
        const passwordValid = await validatePassword();

        if(emailValid && passwordValid){
            setStep("company")
        }
    }


    return (
        <div className="input_group">
            <h1 className="intent-auth_heading text-color-darkpurple">Welcome to Pennicart</h1>
            <TextField
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                value={formValues.user.email}
                onChange={(e) => setFormValues({ ...formValues, user: { ...formValues.user, email: e.target.value } })}
                variant="outlined"
            />
            
            <TextField
                required
                fullWidth
                id="password"
                label="Password"
                name="password"
                value={formValues.user.password}
                onChange={(e) => setFormValues({ ...formValues, user: { ...formValues.user, password: e.target.value } })}
                variant="outlined"
                type="password"
            />
            
            <ul className="password_requirements">
                {passwordRequirements.map((requirement, index) => (
                    <li 
                        key={index} 
                        className="password_requirement" 
                        style={{ color: requirement.valid ? 'green' : 'red' }}
                    >
                        {requirement.text}
                    </li>
                ))}
            </ul>

            <LoadingButton
                size="small"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ fontSize: '13px', padding: '10px 0px'}}
                onClick={() => {
                    submit()
                }}
            >
                Continue
            </LoadingButton>
        </div>
    )
}
