import {
    Button,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import { useEffect } from "react";
import DeleteIcon from '@mui/icons-material/Delete';

const defaultConditions = () => ({
    rules: [
        {
            rule: "",
            condition: "",
            value: "",
        }
    ],
});

const defaultValues = {
    logic_id: "",
    logic_name: "",
    conditions: [defaultConditions()],
};

export default function PurchaseOrder(props: any) {

    const { setLogicType, logicValues, setLogicvalues } = props;

    useEffect(() => {
        if (!logicValues.logic_id) {
            setLogicvalues(defaultValues);
        }
    }, [logicValues.logic_id, setLogicvalues]);

    useEffect(() => {
        if (!Array.isArray(logicValues.conditions)) {
            setLogicvalues({
                ...logicValues,
                conditions: [defaultConditions()],
            });
        }
    }, [logicValues, setLogicvalues]);

    if (!Array.isArray(logicValues.conditions)) {
        return null;
    }

    return (
        <>
            <div className="boxContainerHeader">
                <div className="boxContainerTitle">
                    <h2 className="h2_title">Purchase Order</h2>
                </div>
            </div>

            <TextField
                fullWidth
                label="Name"
                value={logicValues.logic_name}
                onChange={(e) => setLogicvalues({ ...logicValues, logic_name: e.target.value })}
            />

            <div className="input_group">
                {logicValues.conditions.map((condition: any, index: number) => (
                    <div key={index} className="input_group">
                        <div className="dialog_section_title">
                            <div>
                                <h2 className="h2_section_title">Conditions</h2>
                                <span className="subitle">Add special conditions here by clicking 'Add Rule'</span>
                            </div>
                            <IconButton
                                aria-label="delete"
                                disabled={logicValues.conditions.length === 1}
                                onClick={() => {
                                    let conditions = [...logicValues.conditions];
                                    conditions.splice(index, 1);
                                    setLogicvalues({ ...logicValues, conditions: conditions });
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </div>

                        {condition.rules.map((rule: any, ruleIndex: number) => (
                            <div key={ruleIndex} className="input_group">
                                <div className="input_row">

                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Rule</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="rule"
                                            value={rule.rule}
                                            label="Rule"
                                            name="rule"
                                            onChange={(e) => {
                                                let conditions = [...logicValues.conditions];
                                                conditions[index].rules[ruleIndex].rule = e.target.value;
                                                setLogicvalues({ ...logicValues, conditions: conditions });
                                            }}
                                        >
                                            <MenuItem value={"cart_total"}>Cart Total</MenuItem>
                                            <MenuItem value={"weight"}>Weight</MenuItem>
                                            <MenuItem value={"category"}>Category</MenuItem>
                                            <MenuItem value={"custom"}>Custom</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="condition"
                                            value={rule.condition}
                                            label="Condition"
                                            name="rule_condition"
                                            onChange={(e) => {
                                                let conditions = [...logicValues.conditions];
                                                conditions[index].rules[ruleIndex].condition = e.target.value;
                                                setLogicvalues({ ...logicValues, conditions: conditions });
                                            }}
                                        >
                                            <MenuItem value={"="}>Equals</MenuItem>
                                            <MenuItem value={"!="}>Does Not Equal</MenuItem>
                                            <MenuItem value={"=="}>Exact Match</MenuItem>
                                            <MenuItem value={">"}>Greater Than</MenuItem>
                                            <MenuItem value={">="}>Greater Than or Equal To</MenuItem>
                                            <MenuItem value={"<"}>Less Than</MenuItem>
                                            <MenuItem value={"<="}>Less Than or Equal To</MenuItem>
                                            <MenuItem value={"boolean"}>Boolean</MenuItem>

                                        </Select>
                                    </FormControl>

                                    <TextField
                                        fullWidth
                                        label="Value"
                                        value={rule.value}
                                        onChange={(e) => {
                                            let conditions = [...logicValues.conditions];
                                            conditions[index].rules[ruleIndex].value = e.target.value;
                                            setLogicvalues({ ...logicValues, conditions: conditions });
                                        }}
                                    />
                                    <IconButton
                                        aria-label="delete"
                                        disabled={condition.rules.length === 1}
                                        onClick={() => {
                                            let conditions = [...logicValues.conditions];
                                            conditions[index].rules.splice(ruleIndex, 1);
                                            setLogicvalues({ ...logicValues, conditions: conditions });
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </div>
                            </div>
                        ))}

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                let conditions = [...logicValues.conditions];
                                conditions[index].rules.push({
                                    rule: "",
                                    condition: "",
                                    value: "",
                                });
                                setLogicvalues({ ...logicValues, conditions: conditions });
                            }}
                        >
                            Add Rule
                        </Button>

                    </div>
                ))}
            </div>
        </>
    );
}
