import { Button, Dialog, FormControlLabel, styled, Switch, SwitchProps, TextField, Radio, RadioGroup, InputLabel } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import ISwitch from "elements/ISwitch";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PercentIcon from '@mui/icons-material/Percent';

import { ApiRequest } from "GlobalFunctions";
import ic_logic from 'icons/ic_logic.svg';

export default function TaxesEditor(props : any) {

  /*=============================================
  VARIABLES FROM PROPS
  ============================================= */
  const {
    setLoading, 
    openEditor, 
    setOpenEditor, 
    state_id,
    tax_type,
    loadList
  } = props;
  console.log("state_id", state_id);

  const [logicType, setLogicType] = useState<any>({});

  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */
  
  const [formValues, setFormValues] = useState<any>({
    state_id: state_id,
    tax_type: tax_type,  // Inicializado en "product"
    tax: "",
  });
  
  /*=======================================
  GET DETAILS
  =======================================*/
  useEffect(() => {
    if (state_id && tax_type && openEditor) {
      getDetails();
    }
  } , [openEditor]);

  const getDetails = async () => { 
    await ApiRequest({
      method: 'GET',
      url: '/taxes/details',
      query: {
        state_id,
        tax_type
      },
      setResponse: (response: any) => {
        setFormValues(response);
      }
    });
  }

  /*=======================================
  SUBMIT FUNCTION
  =======================================*/
  const handleSubmit = async () => {  

    setLoading(true);

    await ApiRequest({
      method: 'POST',
      url: '/taxes/addedit',
      body: formValues,
      setResponse: (response: any) => {
        if(response.status === "success"){
          enqueueSnackbar('Success', { variant: 'success' });
          setOpenEditor(false);
          loadList();
          
        }else{
          enqueueSnackbar(response.message, { variant: 'error' });
        }
      }
    });
    setLoading(false);
  };



  return (
      <>
        <Dialog 
          open={openEditor}
          onClose={() => setOpenEditor(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          PaperProps={{ sx: { borderRadius: "20px", minWidth:"750px" } }}
        >
          <div className="DialogContainer">
            <div className="dialog_container_title">
                <h2 className="dialog_title"><img src={ic_logic}/>
                  {state_id ? "Edit Tax" : "Add Tax"}
                </h2>
            </div>
            
            <div className="dialogFormContainer">
              <div className="input_group">


                <TextField
                    fullWidth
                    label="Rate"
                    type="number"
                    variant="outlined"
                    value={formValues.tax}
                    onChange={(e) => setFormValues({...formValues, tax: e.target.value})}
                    InputProps={{
                      startAdornment: <PercentIcon />
                    }}
                />

                
              </div>

            </div>
          </div>

          <div className="dialogButtons">

           
              <Button
                  onClick={handleSubmit}
                  variant="contained"
                  className="button_1"
              >
                {state_id ? "Edit Tax" : "Add Tax"}
              </Button>
            

            <Button
                onClick={() => {
                  setOpenEditor(false)
                }}
                variant="contained"
                color="secondary"
                className="button_secondary"
            >
              Cancel
            </Button>
          </div>
        </Dialog>
      </>
  );
}
