import { Avatar, Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { ApiRequest } from 'GlobalFunctions';
import { useEffect, useState } from 'react';
import { PiUserCircleLight } from "react-icons/pi";
import { GiExitDoor } from "react-icons/gi";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { set } from 'lodash';
import { color } from '@mui/system';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

export default function TopMenuUser(props: any) {
    const [stores, setStores] = useState([]);
    const [selectedStore, setSelectedStore] = useState<string | null>(null);
    const { setLoading, data } = props;
    const navigate = useNavigate();

    useEffect(() => {
        GetStores();
        const storedStoreId = localStorage.getItem('store_id');
        if (storedStoreId) {
            setSelectedStore(storedStoreId);
        }
    }, []);

    const GetStores = async () => {
        ApiRequest({
            url: '/stores/getStores',
            method: 'GET',
            setResponse: (response: any) => {
                console.log("RESPONSE", response);
                setStores(response);
            }
        });
    };

    const changeStore = (store_id: any) => {
        setLoading(true);
        setSelectedStore(store_id);
        localStorage.setItem('store_id', store_id);
        window.location.reload();
    };


    const [selectedStoreName, setSelectedStoreName] = useState<string | null>(null);
    
    useEffect(() => {
        if(stores.length === 0) return;

        stores.map((store: any) => {
            console.log("STORE", store);
            console.log("SELECTED STORE", selectedStore);
            if (Number(store.store_id) === Number(selectedStore)) {
                setSelectedStoreName(store.store_name);
            }
        });
    }, [stores, selectedStore]);

    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);


    const logout = async () => {
        localStorage.removeItem('ltkn');
        localStorage.removeItem('store_id');
        localStorage.removeItem('user_id');
        window.location.href = '/';
    }

    const handleNewStore = () => {
        setOpen(false);
        navigate('/webflow?new_store');
    }


    return (
        <>
            <Button
                id="site-select"
                variant="text"
                className='sites_dropdown'
                onClick={(event) => {
                    setOpen(true);
                    setAnchorEl(event.currentTarget);
                }}
                sx={{ border: "none", textTransform: "none" }}
                endIcon={<KeyboardArrowDownIcon />}
            >
                <Avatar sx={{ backgroundColor: "#428EFF" }} src={data.logo}>
                    <PiUserCircleLight size={30} />
                </Avatar>
                <div className='active_site_name'>{selectedStoreName}</div>
            </Button>

            <Menu 
                anchorEl={anchorEl}
                open={open}
                onClose={() => setOpen(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right', // Define que el menú se posicione desde la derecha
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right', // Hace que el menú aparezca hacia la izquierda
                }}
                sx={{ 
                    borderRadius: '20px',
                    marginTop: '40px',
                    padding: '20px',
                }}
                className='sites_dropdown_menu'
            >
                <div className='sites_dropdown_menu_sites'>
                    {stores.map((store: any, index: number) => (
                        <MenuItem 
                            value={store.store_id} 
                            key={index}
                            onClick={() => {
                                setOpen(false);
                                changeStore(store.store_id)
                            }}
                            className='dropdown_item'
                        >
                            <ListItemText>{store.store_name}</ListItemText>
                        </MenuItem>
                    ))}
                    
                    <MenuItem
                        
                        onClick={handleNewStore}
                        className='create_store_btn'
                    >
                        + Create A Store
                    </MenuItem>


                </div>
                <Divider style={{ borderColor: '#ECEBFF' }} />

                <MenuItem 
                    onClick={logout}
                    className='logout'
                >
                    <ListItemText>Logout</ListItemText>
                </MenuItem>
            </Menu>
        </>
    );
}
