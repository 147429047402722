import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Button, Chip, CircularProgress, Dialog, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import moment from 'moment';
import AlertDialog from 'elements/AlertDialog';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useSnackbar } from 'notistack';

import 'assets/css/main.css'; 
import CompanyEditor from './CompanyEditor';
import UsersList from '../Users/UsersList';
 
export default function CompanyList(props: any) {

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {setLoading} = props;

  useEffect(() => {
    setLoading(false);
  }, [])
  
  const [company_list, setUserList] = useState<any []>([]);
  const [total_pages, setTotalPages] = useState(0);

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage=parseInt(localStorage.getItem('page') || "1");

  const [page, setPage] = useState(currentPage);
  const category = queryParams.get('category');
  const [refreshList, setRefreshList] = useState(0);

  const [openEditor, setOpenEditor] = useState(false);
  const [openUserList, setOpenUserList] = useState(false);




  useEffect(() => {
    setLoading(true);
    loadList();
  }, [page])

  /*=========================================================
  LOAD LIST            
  =========================================================*/
  const loadList = () => {
    setLoading(true);
    axios.get(ProjectConfig.api_url+"/companies/list",
    {
      params: {
        page: page,
        role_id: 1,
      }
    })
    .then((response) => {
        console.log("Company List",response.data); 
        setUserList(response.data.list)
        setTotalPages(response.data.total_pages)
        
    })
    .catch((err) => {
      console.log(err.response.data);
    })
    .finally(() => {
      setLoading(false)
    })


    localStorage.setItem('page', page.toString())
  }

  /*=========================================================*/



  /*=========================================================
  DELETE ITEM
  =========================================================*/

  const handleDelete = (company_id:number) => {
    setActiveItem(company_id)
    setOpenAlert(true)
  }



  const deleteItem = () => {

    const body = {
      company_id: activeItem
    }

    axios.post(ProjectConfig.api_url+"/companies/deleteItem", body)
      .then((response) => {
        console.log(response.data);
        setRefreshList(refreshList + 1)
        if(response.data.status === "success"){
          enqueueSnackbar('Empresa eliminada con éxito', { variant: 'success' });
          loadList();
        }
        
      })
      .catch((err) => {
        console.log(err.message);
      })
      .then(() => {
        setOpenAlert(false)
      })
  }

  /*=========================================================*/

// call global snackbar
  
const [openAlert, setOpenAlert] = useState(false);
const [activeItem, setActiveItem] = useState<any>(null);
  
  return (
      <div id='CompaniesList' className='ModuleContainer'>
        <div className="ModuleWrapper"> 
          <div className="boxContainerHeader">
            
            <div className="boxContainerTitle">
              <h2 className="h2_title">Empresas</h2>
              <span className="h2_subtitle">Lista de empresas registradas</span>
            </div>
            
            <div className="boxContainerActions">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setActiveItem(null)
                setOpenEditor(true)
              }}

              startIcon={<AddIcon />}
            >
              Crear empresa
            </Button>

            </div>
            
          </div>

          <div id='CompaniesListIn'>
            
              <Box id='AdminList' className='box100'>
              <TableContainer>
              <Table aria-label="sticky table"> 
                <TableHead>
                  <TableRow>
                    <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>NOMBRE / EMAIL</TableCell>
                    <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>NIT</TableCell>
                    <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>Dirección</TableCell>
                    <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    {company_list.map((company,i) => (
                      <TableRow 
                        
                        key={i}
                        hover
                        role="checkbox"
                      >
                        <TableCell>
                          <span className='flex_title'>{company.company_name}</span>
                          <span className='flex_subtitle'>{company.email}</span>
                        </TableCell>

                      
                        <TableCell>
                          {company.nit}
                        </TableCell>

                        <TableCell>
                          {company.address}
                        </TableCell>

                        <TableCell align="right">

                          <Button 
                            // component={Link}
                            // to={`edit/${company.company_id}`}
                            size="small"
                            variant="contained"
                            color="primary"
                            style={{"marginRight": "10px"}}
                            startIcon={<ModeEditIcon />}
                            onClick={() => {
                              setActiveItem(company.company_id)
                              setOpenEditor(true)
                            }}
                          >
                            Editar
                          </Button>

                          <Button 
                            size="small"
                            variant="contained"
                            color="secondary"
                            startIcon={<ModeEditIcon />}
                            onClick={() => {
                              setActiveItem(company.company_id)
                              setOpenUserList(true)
                            }}
                            
                          >
                            Usuarios
                          </Button>



                          <IconButton aria-label="delete"  color="primary" onClick={() => handleDelete(company.company_id)}>
                            <DeleteOutlineOutlinedIcon />
                          </IconButton>
                      </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            
            <Pagination count={total_pages} page={page}  onChange={(event, value) => {
              setPage(value)
            }
            } 
            color="primary"
            style={{"marginTop": "20px","alignSelf": "center"}}
            />
            </Box>
  
          </div>
        </div>

        <AlertDialog
            open={openAlert}
            setOpen={setOpenAlert}
            subtitle="¿Está seguro que desea eliminar este usuario?"
            send={deleteItem}
        />

        <CompanyEditor
          company_id={activeItem}
          openEditor={openEditor}
          setOpenEditor={setOpenEditor}
          loadList={loadList}
          setLoading={setLoading}
        />

        <Dialog 
          open={openUserList}
          onClose={() => setOpenUserList(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="DialogContainer">
            <UsersList
              company_id={activeItem}
              setLoading={setLoading}
              is_popup={true}
            />
          </div>
        </Dialog>

    </div>
  );
};