
import {ApiRequest} from './GlobalFunctions';
import Dashboard from 'components/Admin/Dashboard/Dashboard';
import {BrowserRouter as Router , Route, Routes, Navigate} from 'react-router-dom'
import CompanyList from 'components/Admin/Companies/CompanyList';
import Plans from 'components/Admin/Plans/PlanList';
import Stores from 'components/Admin/Stores/StoresList';
import StoreEditor from 'components/Admin/Stores/StoreEditor';
import Users from 'components/Admin/Users/UsersList';
import Orders from 'components/Admin/Orders/OrdersList';
import {Permissions} from './Permissions'; 
import MasterLogs from 'components/Admin/MasterLogs/MasterLogs';


import { Component } from 'devextreme-react/core/component';
import LinkWebflow from 'components/Companies/Webflow/Link';

import Account from 'components/Companies/Account/AccountEditor';
import Coupons from 'components/Companies/Coupons/CouponsList'
import Logic from 'components/Companies/Logic/Logic';
import { Fragment, useEffect, useState } from 'react';
import Taxes from 'components/Companies/Taxes/TaxesList';
import Shipping from 'components/Companies/Shipping/ShippingList';
import InvoiceEditor from 'components/Companies/Invoice/InvoiceEditor';
import Payment from 'components/Companies/Payment/Payment';
import StoreOrders from 'components/Companies/Orders/OrdersList';


const componentMap: any = {
  Dashboard,
  CompanyList,
  Plans,
  Stores,
  Users,
  Orders,
  MasterLogs,
  Account,
  Coupons,
  Logic,
  Taxes,
  Shipping,
  InvoiceEditor,
  StoreOrders
}





export default function AppRoutes(props:any) {
  const role_id = Number(localStorage.getItem('role_id'));
  console.log(role_id);
  const {setLoading, GetUserInfo} = props;



 
  
  return (
    <>
      <Routes>
          {Permissions.map((permission, index) => {
            if (permission.role_id === role_id) {
              const DefaultComponent = componentMap[permission.defaultComponent];
              
              // Aquí debe retornar
              return (
                <Fragment key={index}>
                  <Route path="/" element={<Navigate to={permission.defaultComponent} />} />
                  {permission.routes.map((route, index) => {
                    return route.components.map((item, index) => {
                      const Component = componentMap[item.component];
                      return <Route key={index} path={item.path} 
                        element={
                          <Component 
                            setLoading={setLoading}
                            GetUserInfo={GetUserInfo}
                          />
                        } 
                      />
                    });
                  })}
                </Fragment>
              );
            }
            return null; // Retorna null si no cumple la condición
          })}
         

          <Route path="/stores/:store_id" element={<StoreEditor setLoading={setLoading}/>} />
          <Route path="/logic" element={<Logic setLoading={setLoading}/>} />
          <Route path="/webflow" element={<LinkWebflow setLoading={setLoading}/>} />
          <Route path="/pay" element={<Payment setLoading={setLoading}/>} />

        
      </Routes>
    </>
  );
}

 