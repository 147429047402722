import ElavonForm from "components/Companies/Webflow/ElavonForm";
import PaymentDisclaimer from "./PaymentDisclaimer";
import { Button } from "@mui/material";

export default function PaymentForm(props: any) {

    const { 
        planInfo, 
        updatePlan,
        style = 'default',
        setOpenDialog
    } = props;
    
    return (
        <div>
            
            {style === 'custom' && (
            <h2 className="payment_form_title">Change Plan</h2>
            )}

            <PaymentDisclaimer planInfo={planInfo} />
            
            {(planInfo && planInfo.data && planInfo.data.calculated_price.price !== 0) ? (
                <ElavonForm 
                    updatePlan={updatePlan}
                    amount={planInfo.data.calculated_price.price}
                    style={style}
                    setOpenDialog={setOpenDialog}
                />
            ) : (
                <>
                    {style === 'custom' && (
                        <div id='DialogPaymentInfo'>
                            
                        </div>
                    )}


                    
                    <div className="plan_buttons">
                        {style === 'custom' && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setOpenDialog(false)}
                            fullWidth
                        >
                            Cancel
                        </Button>
                        )}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => updatePlan()}
                            fullWidth
                        >
                            Continue
                        </Button>

                    </div>
                    



                </>
            )}
        </div>
    )
}


// Change Plan
// Current Plan:
// Essentials + - Yearly
// $ 456 / Year
// Plan Selected:
// Starter Plan - Yearly
// $ 0 / Year
// You Will Be Charged $0 Today. Your Next Billing Cycle Will Continue With Your New Plan Rate.