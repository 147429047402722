import { useState, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import { FormControl, InputLabel, MenuItem, Select, TextField, Button } from "@mui/material";
import { ApiRequest } from "GlobalFunctions";
import query from "devextreme/data/query";
import { useSnackbar, withSnackbar } from 'notistack';
import {ProjectConfig} from 'Global';
import Box from "elements/Box";
import Sites from "./Sites";
import Payment from "../Payment/Payment";
import { useNavigate, useLocation } from "react-router-dom";
import logo from '../../../assets/img/logo.svg';

export default function LinkWebflow(props: any) {
    const navigate = useNavigate();
    const { setLoading, new_store } = props;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [sites, setSites] = useState([]);

    /*CHECK IF NEW STORE
    =============================================*/
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const isNewStore = params.has('new_store') || new_store;

    console.log("NEW STORE ",isNewStore);
    


    
    const [siteSelected, setSiteSelected] = useState<string | null>(null);

    const [siteLinked, setSiteLinked] = useState(false);

    const openWebflowModal = async () => {
        const ltkn = localStorage.getItem('ltkn');
    
        let url = `https://webflow.com/oauth/authorize?response_type=code`
        url += `&client_id=${ProjectConfig.WEBFLOW_CLIENT_ID}`
        url += `&redirect_uri=`
        url += `&state=${ltkn}`
        url += `&scope=assets%3Aread%20assets%3Awrite%20authorized_user%3Aread%20cms%3Aread%20cms%3Awrite%20custom_code%3Aread%20custom_code%3Awrite%20forms%3Aread%20forms%3Awrite%20pages%3Aread%20pages%3Awrite%20sites%3Aread%20sites%3Awrite%20ecommerce%3Aread%20ecommerce%3Awrite%20users%3Aread%20users%3Awrite`;
    
        const popup = window.open(url, '_blank', 'location=yes,scrollbars=yes,status=yes');
    
        if (popup) {
            const popupCheckInterval = setInterval(() => {
                if (popup.closed) {
                    clearInterval(popupCheckInterval);
                    getWebflowSites();
                }
            }, 500); // Verifica cada 500ms si la ventana sigue abierta
        }
    };

    /*=============================================
    GET WEBFLOW SITES
    =============================================*/
    const getWebflowSites = async () => {
        setLoading(true);
        await ApiRequest({
            url: '/webflow/getWebflowSites',
            method: 'GET',
            setResponse: (response: any) => {
                if(response.status === "success"){
                    setSites(response.sites);
                }
                console.log("SITES RESPONSE ",response);
                setLoading(false);
            }
            
        });
    }

    useEffect(() => {
        // checkSiteLinked();
        getWebflowSites();
    }, []);
    

    const updateSiteId = async () => {
        setLoading(true);
        await ApiRequest({
            url: '/stores/updateSiteId',
            method: 'POST',
            body: {
                site: siteSelected,
                store_id: localStorage.getItem('store_id'),
                new_store: isNewStore
            },
            setResponse: (response: any) => {
                console.log("UPDATE SITE RESPONSE ",response);
                if(response.status === "success"){
                    enqueueSnackbar('Webflow project linked successfully', { variant: 'success' });
                    setSiteLinked(true);
                    localStorage.setItem('store_id', response.store_id);
                    window.location.href = '/pay';
                } else {
                    enqueueSnackbar(response.message, { variant: 'error' });
                }
                setLoading(false);

            }
        });
        
    }


    const checkSiteLinked = async () => {
        setLoading(true);
        await ApiRequest({
            url: '/stores/checkSiteLinked',
            method: 'GET',
            query: {
                store_id: localStorage.getItem('store_id')
            },
            setResponse: (response: any) => {
                if(response.status === "success"){
                    setSiteLinked(true);
                }
                // setLoading(false);
            }
        });
    }
    


    return (
        <>


            <div className="section-gradient_component">
                <div className="section-gradient_hero-1"></div>
                <div className="section-gradient_hero-2"></div>
                <div className="section-gradient_hero-3"></div>
                <div className="section-gradient_hero-5"></div>
            </div>
            <div id='structure'>
                <div id="main-container">
                    <header data-pc="nav-component" className="nav_padding">
                        <div
                        data-w-id="68dd9a17-0234-0921-12f0-3adaa0a22869"
                        className="nav-cart_demo-background"
                        ></div>
                        <div nav-scroll="true" className="nav_container">
                        <div className="nav_container-background"></div>
                        <a href="/" className="nav_logo-link w-nav-brand" aria-label="home">
                            <div className="nav_logo w-embed">
                            <img src={logo} alt="" className="nav_logo-image" />
                            </div>
                        </a>
                        </div>
                    </header>
                        

                    <div id="main-content" className="alt-main-content">
                        <Box className='intent-auth_component'>
                            <div className="pennicart_heading">
                                <h1>Connect Webflow Project</h1>
                                <p>Select a site to link</p>
                            </div>
                            
                            <div className="input_group">

                                {sites.length === 0 ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        sx={{ fontSize: '13px', padding: '10px 0px'}}
                                        onClick={() => {
                                            openWebflowModal()
                                        }}
                                    >
                                        Connect Now
                                    </Button>
                                ) : (
                                    <>
                                        <Sites 
                                            sites={sites} 
                                            siteSelected={siteSelected}
                                            setSiteSelected={setSiteSelected}
                                            updateSiteId={updateSiteId}
                                        />
                                        <Button
                                            variant="text"
                                            color="primary"
                                            size="large"
                                            sx={{ fontSize: '13px', padding: '10px 0px'}}
                                            onClick={() => {
                                                openWebflowModal()
                                            }}
                                        >
                                            Re link Webflow
                                        </Button>
                                    </>
                                )}

                            
                            
                                
                            </div>
                        </Box>
                    </div>
                </div>
            </div>
        </>
    )
}
