import { Button, TextField } from "@mui/material";
import { ApiRequest } from "GlobalFunctions";
import { useState } from "react";
import { useSnackbar, withSnackbar } from 'notistack';


export default function ElavonForm(props: any) {

    const { 
        updatePlan, 
        amount, 
        style,
        setOpenDialog
} = props;

    const [formValues, setFormValues] = useState({
        name: "",
        card_number: "",
        card_expiration: "",
        card_cvv: "",
    });
    const [errors, setErrors] = useState({
        card_expiration: false,
    });

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();


    const formatCardNumber = (value: string) => {
        return value
            .replace(/\D/g, '') // Eliminar cualquier carácter que no sea un dígito
            .replace(/(.{4})/g, '$1 ') // Añadir un espacio después de cada 4 dígitos
            .trim() // Eliminar cualquier espacio final extra
            .substring(0, 19); // Limitar el total de caracteres a 19 (16 dígitos + 3 espacios)
    };

    const formatExpirationDate = (value: string) => {
        let cleanedValue = value.replace(/\D/g, '');

        if (cleanedValue.length > 2) {
            cleanedValue = cleanedValue.substring(0, 2) + '/' + cleanedValue.substring(2);
        }

        return cleanedValue.substring(0, 5);
    };

    const handleExpirationChange = (e: any) => {
        const formattedValue = formatExpirationDate(e.target.value);
        setFormValues({ ...formValues, card_expiration: formattedValue });

        validateExpirationDate(formattedValue);
    };

    const validateExpirationDate = (value: string) => {
        const [month, year] = value.split('/').map(Number);
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1; // Los meses son 0-indexados
        const currentYear = currentDate.getFullYear() % 100; // Obtener los últimos dos dígitos del año

        let isValid = true;

        // Validar que el mes no sea superior a 12
        if (month > 12 || month < 1) {
            isValid = false;
        }

        // Validar que la fecha sea superior a la presente
        if (year < currentYear || (year === currentYear && month < currentMonth)) {
            isValid = false;
        }

        setErrors({ ...errors, card_expiration: !isValid });
    };


    const submitPayment = async () => {
        
        if (!formValues.name || formValues.name.length < 2) {
            enqueueSnackbar('Please enter a valid name', { variant: 'error' });
            return;
        }

        if (!formValues.card_number || formValues.card_number.length < 19) {
            enqueueSnackbar('Please enter a valid card number', { variant: 'error' });
            return;
        }

        if (errors.card_expiration) {
            enqueueSnackbar('Please enter a valid expiration date', { variant: 'error' });
            return;
        }

        if (!formValues.card_cvv || formValues.card_cvv.length < 3) {
            enqueueSnackbar('Please enter a valid CVV', { variant: 'error' });
            return;
        }



        updatePlan(formValues, amount);

        
    }



    return (
        <div className="elavon_form_wrapper">
            <div className="input_group">
                <TextField 
                    label="Name"
                    variant="outlined"
                    value={formValues.name}
                    onChange={(e) => setFormValues({ ...formValues, name: e.target.value })}
                />

                <TextField 
                    label="Card Number"
                    variant="outlined"
                    value={formValues.card_number}
                    onChange={(e) => setFormValues({ ...formValues, card_number: formatCardNumber(e.target.value) })}
                    inputProps={{ maxLength: 19 }}
                    
                />

                <TextField 
                    label="Expiration Date (MM/AA)"
                    variant="outlined"
                    value={formValues.card_expiration}
                    onChange={handleExpirationChange}
                    error={errors.card_expiration}
                    helperText={errors.card_expiration ? "Invalid date MM/AA" : ""}
                />

                <TextField 
                    label="CVV"
                    variant="outlined"
                    value={formValues.card_cvv}
                    onChange={(e) => setFormValues({ ...formValues, card_cvv: e.target.value })}
                    inputProps={{ maxLength: 4 }}
                />

                    <div className="plan_buttons">
                        {style === 'custom' && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setOpenDialog(false)}
                            fullWidth
                        >
                            Cancel
                        </Button>
                        )}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={submitPayment}
                            fullWidth
                        >
                            Pay
                        </Button>

                    </div>

           
                    

                
            </div>
        </div>
    );
}
