import { Button, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import axios from "axios";
import { ProjectConfig } from "Global";
import { ApiRequest } from "GlobalFunctions";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import { useSnackbar } from "notistack";
import AlertDialog from "elements/AlertDialog";
import CouponsEditor from "./CouponsEditor";

export default function CouponsList(props: any) {
    const { setLoading } = props;

    const [openEditor, setOpenEditor] = useState(false);
    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
    const [activeItem, setActiveItem] = useState<any>(null);
    const [openAlert, setOpenAlert] = useState(false);
    const [list, setList] = useState<any[]>([]);
    const [pagination, setPagination] = useState({
      total_pages: 1,
      total_items: 0,
    });
  
    const currentPage = parseInt(localStorage.getItem('page') || "1");
    const [page, setPage] = useState(currentPage);

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setLoading(true);
        loadList();
      }, [page]);

    /*=========================================================
    LOAD LIST
    =========================================================*/
    const loadList = async () => {
        setLoading(true);
        await ApiRequest({
            method: 'GET',
            url: '/coupons/list',
            setResponse: (response: any) => {
                setList(response.list);
                setPagination(response.pagination);
                setLoading(false);
            }
        })
    }

    /*=========================================================
    HANDLE MENU OPEN
    =========================================================*/
    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, item: any) => {
        setMenuAnchor(event.currentTarget);
        setActiveItem(item);
    };

    /*=========================================================
    HANDLE MENU CLOSE
    =========================================================*/
    const handleMenuClose = () => {
        setMenuAnchor(null);
    };

    /*=========================================================
    DELETE ITEM
    =========================================================*/
    const handleDelete = (coupon_id: number) => {
        setActiveItem(coupon_id);
        setOpenAlert(true);
    }

    const deleteItem = () => {
        const body = { coupon_id: activeItem };
        axios.post(ProjectConfig.api_url + "/coupons/deleteItem", body)
        .then((response) => {
            if (response.data.status === "success") {
                enqueueSnackbar('Item deleted successfully', { variant: 'success' });
                loadList();
            }
        })
        .catch((err) => {
            console.log(err.data);
        })
        .then(() => {
            setOpenAlert(false);
        });
    }

    return (
        <div className='ModuleContainer'>
            <div className="ModuleHeader">
                <div className="ModuleTitle">
                    <h1>Coupons</h1>
                </div>
                <div className="ModuleActions">
                    <Button
                        variant="outlined"
                        color="info"
                        onClick={() => {
                            setActiveItem(null);
                            setOpenEditor(true);
                        }}
                        className="btnList"
                        startIcon={<AddIcon />}
                    >
                        Add New Coupon
                    </Button>
                </div>
            </div>
            
            <div className='ModuleContent'>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Coupon Name</TableCell>
                            <TableCell>Coupon Code</TableCell>
                            <TableCell>Terms</TableCell>
                            <TableCell>Redemptions</TableCell>
                            <TableCell>Expires</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list.map((item, i) => (
                            <TableRow key={i}>
                                <TableCell>{item.coupon_name}</TableCell>
                                <TableCell>{item.coupon_code}</TableCell>
                                <TableCell>{item.terms}</TableCell>
                                <TableCell>{item.redemptions}</TableCell>
                                <TableCell>{item.end_date}</TableCell>
                                <TableCell align="right">
                                    <IconButton aria-label="edit" color="primary" onClick={(event) => handleMenuOpen(event, item)}>
                                        <MoreHorizIcon />
                                    </IconButton>
                                    <Menu
                                        anchorEl={menuAnchor}
                                        open={Boolean(menuAnchor && activeItem && activeItem.coupon_id === item.coupon_id)}
                                        onClose={handleMenuClose}
                                    >
                                        <MenuItem onClick={() => {
                                            setActiveItem(item);
                                            setOpenEditor(true);
                                            handleMenuClose();
                                        }}>
                                            <EditIcon fontSize="small" />
                                            Edit
                                        </MenuItem>
                                        <MenuItem onClick={() => handleDelete(item.coupon_id)}>
                                            <DeleteOutlineOutlinedIcon fontSize="small" />
                                            Remove
                                        </MenuItem>
                                    </Menu>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>

            <CouponsEditor
                setLoading={setLoading}
                openEditor={openEditor}
                setOpenEditor={setOpenEditor}
                coupon_id={activeItem ? activeItem.coupon_id : null}
                loadList={loadList}
            />

            <AlertDialog
                open={openAlert}
                setOpen={setOpenAlert}
                subtitle="Are you sure you want to delete this item?"
                send={deleteItem}
            />
        </div>
    )
}
