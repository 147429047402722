import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartOptions,
    Tick,
} from 'chart.js';
import ChartJS from 'chart.js/auto'; // Importa ChartJS para el registro automático de componentes
import { Button } from '@mui/material';

// Registro de los componentes que se usarán
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const LineChart = (params: any) => {
    const { data } = params;

    const [filter, setFilter] = useState("users");

    const handleFilterClick = (newFilter: string) => {
        setFilter(newFilter);
    }

    if (!data) {
        return null;
    }

    const options: ChartOptions<'line'> = {
        maintainAspectRatio: false, // Permite que el gráfico llene el contenedor
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    callback: function(tickValue: string | number, index: number, ticks: Tick[]) {
                        if (typeof tickValue === 'number') {
                            if (tickValue >= 1000) {
                                return tickValue / 1000 + 'k'; // Convertir a 'k'
                            } else {
                                return tickValue;
                            }
                        }
                        return tickValue;
                    },
                },
                grid: {
                    display: false // Retira la grilla en el eje y
                }
            },
            x: {
                grid: {
                    display: false // Retira la grilla en el eje x
                }
            },
        },
        plugins: {
            legend: {
                display: false // Oculta las leyendas
            }
        }
    };

    return (
        <div className="planChart">
            <div className='planChart_header'>
                <div className='planChart_buttons'>
                    <Button 
                        onClick={() => handleFilterClick("users")}
                        className={filter === "users" ? "active" : ""}
                        sx={{
                            '&:hover': {
                              backgroundColor: 'transparent',
                              color: '#7963ff'
                            }
                          }}
                    >
                        Users
                    </Button>
                    <Button 
                        onClick={() => handleFilterClick("revenue")}
                        className={filter === "revenue" ? "active" : ""}
                        sx={{
                            '&:hover': {
                              backgroundColor: 'transparent',
                              color: '#7963ff'
                            }
                          }}
                    >
                        Monthly Recurring Revenue
                    </Button>
                </div>

                <div className='planChart_legends'>
                    {data.totals.map((total: any, index: number) => (
                        <div className='planChart_legend' key={index}>
                            <div className='planChart_value'>
                                {total.total}
                            </div>
                            <div className='planChart_label'>
                                <div className='planChart_legendCircle' style={{backgroundColor: total.color}}></div>
                                <span>{total.plan_name}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            
            <div style={{ height: '200px', position: 'relative' }}>
                <Line data={data} options={options} />
            </div>
        </div>
    );
};

export default LineChart;
