import { useEffect, useState } from "react";
import moment from "moment";

export default function PaymentDisclaimer(props: any) {
    const { planInfo } = props;
    const [message, setMessage] = useState<JSX.Element | null>(null);

    useEffect(() => {
        if (planInfo && planInfo.data) {
            const { calculated_price, plan_info } = planInfo.data;

            const plan_price = calculated_price.price;
            const type_of_change = calculated_price.type_of_change;
            const plan_type = calculated_price.plan_type;
            const next_billing_cycle = calculated_price.current_plan_active_until;

            // Formatear la fecha de la próxima facturación usando moment
            const next_billing_cycle_date = moment(next_billing_cycle).format('MMMM Do, YYYY');

            let msg: JSX.Element | null = null;

            if (type_of_change === "upgrade") {
                msg = (
                    <div className="plan_info_message">
                        You have selected <span className="plan_selected_highlight">{plan_info.title} - Pay {plan_type}</span> - Your account will be charged <span className="plan_selected_highlight">${plan_price}</span> at your next billing cycle which is: <span className="plan_selected_highlight">{next_billing_cycle_date}</span>.
                    </div>
                );
            } else if (type_of_change === "downgrade") {
                msg = (
                    <div className="plan_info_message">
                        You have selected <span className="plan_selected_highlight">{plan_info.title} - Pay {plan_type}</span> - This change will take effect at your next billing cycle which is: <span className="plan_selected_highlight">{next_billing_cycle_date}</span>.
                    </div>
                );
            } else {
                msg = (
                    <div className="plan_info_message">
                        You have selected <span className="plan_selected_highlight">{plan_info.title} - Pay {plan_type}</span> - You will be charged <span className="plan_selected_highlight">${plan_price}</span> Today.
                    </div>
                );
            }


            // if (plan_price === 0) {
            //     msg = null;
            // }

            setMessage(msg);
        }
    }, [planInfo]);

    return (
        <div className="plan_price_message_container">
            {message}
        </div>
    );
}
