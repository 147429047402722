import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';


export default function BoxIndicator(props: any) {
  const {
    title,
    indicator,
    percentageLabel,
    icon
  } = props;

  let iconSymbol = null;
  if(icon){
    iconSymbol = icon === "up" ? 
      <TrendingUpIcon sx={{fontSize: 16}} /> 
      : 
      <TrendingDownIcon sx={{fontSize: 16}}/>;
  }
  return (
    <div className="boxIndicator">
      <div className="boxIndicatorContent">
        <h2>{title}</h2>
        <div className="boxIndicatorContentInner">
          <span className="indicatorValue">{indicator}</span>
          <span className="percentageLabel">{percentageLabel}{iconSymbol}</span>
        </div>
      </div>
    </div>
  );
}
