import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import goalStep from './goal_step.png';
import { Button, IconButton, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ProjectConfig } from 'Global';
import ListPagination from 'elements/ListPagination';
import ic_link from 'icons/ic_link.svg';

export default function TopAccounts(props: any) {

/*=============================================
  GET TOP STORES
  =============================================*/
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("topSellers");
  const [sortOrder, setSortOrder] = useState("desc");
  const [activeSort, setActiveSort] = useState("topSellers");
  const [pagination, setPagination] = useState({
    total_pages: 1,
    total_items: 0,
  });

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage = parseInt(localStorage.getItem('page') || "1");

  const [page, setPage] = useState(currentPage);

  const getTopStores = async () => {
    setLoading(true);

    axios.get(ProjectConfig.api_url + "/top_stores", {
      headers: {
        'ltkn': localStorage.getItem('ltkn')
      },
      params: {
        filter: filter,
        page: page
      }
    })
    .then((response) => {
      console.log("TOPACCOUNTS data ", response.data); 
      setData(response.data.list);
      setPagination(response.data.pagination);
    })
    .catch((err) => {
      console.log(err.response.data);
    })
    .finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    getTopStores();
  }, [filter, page]);

  const sortData = (key: string, order: string) => {
    const sortedData = [...data].sort((a, b) => {
      if (order === "asc") {
        return a[key] > b[key] ? 1 : -1;
      } else {
        return a[key] < b[key] ? 1 : -1;
      }
    });
    setData(sortedData);
    setSortOrder(order);
    setActiveSort(key);
  };

  const handleFilterClick = (newFilter: string) => {
    setFilter(newFilter);
    setSortOrder("desc"); // Reset sort order to 'desc'
    setActiveSort(newFilter);
  };

  return (
    <div id="TopAccounts">
      <span className='upTitle'>Top Accounts</span>
      <div className='topAccountsHeader'>
        <div className='topAccountsFilters'>
          <Button
            onClick={() => handleFilterClick("topSellers")}
            className={filter === "topSellers" ? "active" : ""}
            sx={{
              '&:hover': {
                backgroundColor: 'transparent',
                color: '#7963ff'
              }
            }}
            
          >Top Sellers</Button>
          <Button
            onClick={() => handleFilterClick("checkoutRate")}
            className={filter === "checkoutRate" ? "active" : ""}
            sx={{
              '&:hover': {
                backgroundColor: 'transparent',
                color: '#7963ff'
              }
            }}
          >Checkout Rate</Button>
          <Button
            onClick={() => handleFilterClick("totalOrders")}
            className={filter === "totalOrders" ? "active" : ""}
            sx={{
              '&:hover': {
                backgroundColor: 'transparent',
                color: '#7963ff'
              }
            }}
          >Orders</Button>
        </div>

        <div className='orderButtons'>
          <IconButton 
            className={`orderButton ${activeSort === filter && sortOrder === "asc" ? "active" : ""}`}
            onClick={() => sortData(filter, "asc")}
          >
            <TrendingUpIcon sx={{fontSize: 16}} />
          </IconButton>
          <IconButton 
            className={`orderButton ${activeSort === filter && sortOrder === "desc" ? "active" : ""}`}
            onClick={() => sortData(filter, "desc")}
          >
            <TrendingDownIcon sx={{fontSize: 16}} />
          </IconButton>
        </div>
      </div>

      <TableContainer>
        <Table aria-label="sticky table"> 
          <TableHead>
            <TableRow>
              <TableCell sx={{color:'#a8a8a8', borderBottom:"none"}}>Company</TableCell>
              <TableCell sx={{color:'#a8a8a8', borderBottom:"none"}}>Sales</TableCell>
              <TableCell sx={{color:'#a8a8a8', borderBottom:"none"}}>Checkout Rate</TableCell>
              <TableCell sx={{color:'#a8a8a8', borderBottom:"none"}}>Orders</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item: any, i: number) => (
              <TableRow key={i}>
                <TableCell>{item.company_name}</TableCell>
                <TableCell>$ {Number(item.total_sales).toLocaleString()}</TableCell>
                <TableCell>{item.checkout_rate}</TableCell>
                <TableCell>{item.total_orders}</TableCell>
                <TableCell align='right'>
                  <IconButton onClick={() => window.open(item.domain, "_blank")}>
                    <img src={ic_link} alt="link" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      
        <ListPagination 
          pagination={pagination} 
          page={page}  
          onChange={(event: any, value: any) => {
            setPage(value);
          }} 
          label="Stores"
          style={{ marginTop: "20px", alignSelf: "center" }}
        />
    </div>
  );
}
