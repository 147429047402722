import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Link,
    TextField,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

import Box from 'elements/Box';
import axios from 'axios';
import { useSnackbar, withSnackbar } from 'notistack';
import { ProjectConfig } from 'Global';

export default function FormCredentialsSent( props: any ) {
    
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const {
        logo,
        setState,
        email,
        setLoginData,
        loginDataDefault,
        code,
        setCode,
    } = props;



    

    const checkCode = () => {
      setLoading(true);
      axios
        .get(ProjectConfig.api_url+"/users/validate_code", {
          params: {
            email,
            code,
          },
        })
        .then((response) => {
          console.log(response.data);
          if(response.data.status === "success"){
              enqueueSnackbar(response.data.message, { variant: 'success' });
              setState('changePass');
          } else {
              enqueueSnackbar(response.data.message, { variant: 'error' });
          }
          setLoading(false);
         
        })
        .catch((err) => {
          console.log(err.data);
          setLoading(false);
        });
    }

    const [loading, setLoading] = React.useState(false);
    return(
        <Box id='loginBox' className='intent-auth_component'>

          

          <Link
            component="button"
            variant="subtitle2"
            underline="none"
            onClick={() => {
                setLoginData(loginDataDefault);
                setState('login')
            }}
            sx={{display:'flex', alignItems:'center', fontSize: 'small', justifyContent: 'start', columnGap: '5px', padding: '0px 0px 10px 0px'}}
          >
            <ArrowBack sx={{ fontSize: 'small' }} />
            Back
          </Link>

          <p>Enter the code sent to your email</p>
          
            <TextField
              required
              fullWidth
              id="code"
              label="Code"
              name="email"
              type="text"
              autoComplete="email"
              autoFocus
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />

            <LoadingButton
              size="small"
              type="submit"
              loading={loading}
              fullWidth
              variant="contained"
              sx={{ fontSize: '13px', padding: '10px 0px'}}
              onClick={checkCode}

            >
              Verify
            </LoadingButton>


        </Box>
    );
}