import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Button, Chip, CircularProgress, Dialog, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link, useParams, useNavigate } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import moment from 'moment';
import AlertDialog from 'elements/AlertDialog';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useSnackbar } from 'notistack';

import 'assets/css/main.css'; 
import ListPagination from 'elements/ListPagination';
import FiltersByTable from 'elements/Filters/FiltersByTable';
import FiltersApplied from 'elements/Filters/FiltersApplied';
import { BiEdit } from "react-icons/bi";


export default function CompanyList(props: any) {

  const navigate = useNavigate();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {setLoading} = props;

  useEffect(() => {
    setLoading(false);
  }, [])
  
  const [list, setList] = useState<any []>([]);
  const [pagination, setPagination] = useState({
    total_pages: 1,
    total_items: 0,
  });

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage=parseInt(localStorage.getItem('page') || "1");

  const [page, setPage] = useState(currentPage);
  const category = queryParams.get('category');
  const [refreshList, setRefreshList] = useState(0);

  const [openEditor, setOpenEditor] = useState(false);
  const [openUserList, setOpenUserList] = useState(false);

  const [filtersList, setFiltersList] = useState<any[]>([]);
  const [filtersValues, setFiltersValues] = useState<any>([]);



  useEffect(() => {
    setLoading(true);
    loadList();
  }, [page, filtersValues])



  /*=========================================================
  LOAD LIST            
  =========================================================*/
  const loadList = () => {
    setLoading(true);
    axios.get(ProjectConfig.api_url+"/stores/list",
    {
      params: {
        page: page,
        role_id: 1,
        filters: filtersValues
      }
    })
    .then((response) => {
        console.log("Stores List",response.data); 
        setList(response.data.list)
        setPagination(response.data.pagination)
        setFiltersList(response.data.filtersList)
        
    })
    .catch((err) => {
      console.log(err.response.data);
    })
    .finally(() => {
      setLoading(false)
    })


    localStorage.setItem('page', page.toString())
  }

  /*=========================================================*/



  /*=========================================================
  DELETE ITEM
  =========================================================*/

  const handleDelete = (company_id:number) => {
    setActiveItem(company_id)
    setOpenAlert(true)
  }



  const deleteItem = () => {

    const body = {
      company_id: activeItem
    }

    axios.post(ProjectConfig.api_url+"/companies/deleteItem", body)
      .then((response) => {
        console.log(response.data);
        setRefreshList(refreshList + 1)
        if(response.data.status === "success"){
          enqueueSnackbar('Empresa eliminada con éxito', { variant: 'success' });
          loadList();
        }
        
      })
      .catch((err) => {
        console.log(err.message);
      })
      .then(() => {
        setOpenAlert(false)
      })
  }

  /*=========================================================*/

// call global snackbar
  
const [openAlert, setOpenAlert] = useState(false);
const [activeItem, setActiveItem] = useState<any>(null);
  
  return (
      <div id='CompaniesList' className='ModuleContainer'>
        <div className="ModuleWrapper"> 
          <div className="boxContainerHeader">
            
            <div className="boxContainerTitle">
              <h1>Stores</h1>
            </div>
            <FiltersByTable 
              filters={filtersList} 
              setFilters={setFiltersValues} 
              searchBy={['companies.company_id', 'company_name']}
            />
            
          </div>
          <FiltersApplied filters={filtersValues} setFilters={setFiltersValues} />
          <div id='CompaniesListIn'>
            
          <TableContainer>
            <Table aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ color: '#B3B3B3', borderBottom: 'none' }}>UID</TableCell>
                  <TableCell sx={{ color: '#B3B3B3', borderBottom: 'none' }}>Company</TableCell>
                  <TableCell sx={{ color: '#B3B3B3', borderBottom: 'none' }}>Store</TableCell>
                  <TableCell sx={{ color: '#B3B3B3', borderBottom: 'none' }}>Sign up Date</TableCell>
                  <TableCell sx={{ color: '#B3B3B3', borderBottom: 'none' }}>Package</TableCell>
                  <TableCell sx={{ color: '#B3B3B3', borderBottom: 'none' }}>Subscription</TableCell>
                  <TableCell sx={{ color: '#B3B3B3', borderBottom: 'none' }}>Status</TableCell>
                  <TableCell sx={{ color: '#B3B3B3', borderBottom: 'none' }}>Last Payment</TableCell>
                  <TableCell sx={{ color: '#B3B3B3', borderBottom: 'none' }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((item, i) => {

                  let status_color = "";
                  let status = "";
                  
                  if(item.plan_status === 'successful'){
                    status = "Successful"
                    status_color = '#0A7715'
                  }
                  else if(item.plan_status === 'pending_renewal'){
                    status = `Declined ${item.renewal_attempts}/7`
                    status_color = '#E88C01'
                  }
                  else{
                    status = "Downgraded - Card Failed"
                    status_color = '#D80000'
                  }

                  return(
                  <TableRow key={i} hover role="checkbox" sx={{
                    '&:hover': {
                      backgroundColor: '#ecebff !important', // Color del hover
                    },
                  }}>
                    <TableCell>{item.store_id}</TableCell>
                    <TableCell>{item.company_name}</TableCell>
                    <TableCell>{item.store_name}</TableCell>
                    <TableCell>{item.created_at ? item.created_at : '-'}</TableCell>
                    <TableCell>{item.plan_name}</TableCell>
                    <TableCell>{item.current_plan_type ? item.current_plan_type.charAt(0).toUpperCase() + item.current_plan_type.slice(1) : '-'}</TableCell>
                    <TableCell style={{ color: status_color }}>
                      {status ? status : '-'}
                    </TableCell>

                    <TableCell>{item.last_payment_date ? item.last_payment_date : '-'}</TableCell>

                    <TableCell align="right">
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={() => {
                          navigate(`/stores/${item.store_id}`);
                        }}
                      >
                        <BiEdit fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )})}
              </TableBody>
            </Table>
          </TableContainer>

            
            <ListPagination pagination={pagination} page={page}  onChange={(event:any, value:any) => {
              setPage(value) 
            }} 
            label="Stores"
            color="primary"
            style={{"marginTop": "20px","alignSelf": "center"}}
            />
  
          </div>
        </div>

        <AlertDialog
            open={openAlert}
            setOpen={setOpenAlert}
            subtitle="¿Está seguro que desea eliminar este usuario?"
            send={deleteItem}
        />

        

        

    </div>
  );
};