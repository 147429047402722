import { IconButton } from "@mui/material";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Fragment } from "react";

export default function FiltersApplied(props: any) {
  return (
    <Fragment>
      {/* SHOWS ONLY IF PROPS.FILTERS EXISTS AND IS NOT EMPTY */}
      {props.filters.length > 0 && (
        <div className="filtersApplied">
          Filters applied:
          {props.filters.map((filter: any, index: any) => {
            // Determine display text based on type
            let displayText;

            if (filter.type === 'order') {
              // Display for ordering
              displayText = `Sort by: ${filter.label}`;
            } else if (filter.type === 'date_range' && filter.value.startDate && filter.value.endDate) {
              // Display for date range filters
              const value = `${filter.value.startDate} - ${filter.value.endDate}`;
              displayText = `${filter.label} between ${value}`;
            } else {
              // Display for other filters
              let value;
              if (typeof filter.value === 'string') {
                // If it's a string, cut it if longer than 10 characters
                value = filter.value.length < 10 ? filter.value : filter.value.substring(0, 10) + "...";
              } else {
                // For other cases, display the value as is
                value = JSON.stringify(filter.value);
              }

              // Combine label, condition, and value for display
              displayText = `${filter.label} ${filter.condition} ${value}`;
            }

            return (
              <div className="filterItem" key={index}>
                <span>{displayText}</span>
                <IconButton
                  size="small"
                  onClick={() => {
                    const newFilters = props.filters.filter((_: any, i: any) => i !== index);
                    props.setFilters(newFilters);
                  }}
                >
                  <DeleteOutlineOutlinedIcon sx={{ fontSize: 15 }} />
                </IconButton>
              </div>
            );
          })}
        </div>
      )}
    </Fragment>
  );
}
