import { Button, Dialog, FormControlLabel, styled, Switch, SwitchProps, TextField } from "@mui/material"
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import {ProjectConfig} from 'Global';
import { useSnackbar, withSnackbar } from "notistack";
import ISwitch from "elements/ISwitch";
import AutocompleteField from "elements/AutocompleteField";

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LogicMenu from "./components/LogicMenu";
import ShippingLogic from "./components/ShippingLogic";
import PurchaseOrder from "./components/PurchaseOrder";
import BulkDiscount from "./components/BulkDiscount";
import HideVariables from "./components/HideVariables";
import BuildBundle from "./components/BuildBundle";
import Custom from "./components/Custom";
import { ApiRequest } from "GlobalFunctions";
import ic_logic from 'icons/ic_logic.svg';

export default function LogicEditor(props : any) {

  /*=============================================
  VARIABLES FROM PROPS
  ============================================= */
  const {
    setLoading, 
    openEditor, 
    setOpenEditor, 
    logic_id,
    loadList
  } = props;
  console.log("logic_id", logic_id);


  const [logicType, setLogicType] = useState<any>({});


  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */
  
  const [logicValues, setLogicValues] = useState<any>({});

  

  /*=======================================
  GET USER DETAILS IF USER_ID EXISTS
  =======================================*/
  useEffect(() => {
    if (logic_id) {
      axios.get(ProjectConfig.api_url + "/logic/details", {
          params: {
            logic_id: logic_id,
          },
        })
        .then((response:any) => {
          console.log("DETAILS res", response)
          setLogicValues(response.data.data);
          setLogicType(response.data.logic_type);
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    }
    else{
      setLogicValues({});
      setLogicType({})
    }
    
  } , [openEditor]);





  /*=======================================
  SUBMIT FUNCTION
  =======================================*/
  const handleSubmit = () => {  
    
    setLoading(true);
    const body = new FormData();
    body.append("formValues", JSON.stringify(logicValues));
    body.append("logicType", JSON.stringify(logicType));
    console.log("formValues", logicValues);
    
    
    ApiRequest({
      method: 'POST',
      url: '/logic/addedit',
      body: {
        ...logicValues,
        ...logicType
      },
      setResponse: (response: any) => {
        if(response.status === "success"){
          enqueueSnackbar('Success', { variant: 'success' });
          setOpenEditor(false);
          loadList();
          
        }else{
          enqueueSnackbar(response.message, { variant: 'error' });
        }
      }
    }); 
  };


  const getsubTitle = () => {
    if(Object.keys(logicType).length > 0){
      if(logicType.logic_type_id === 1){
        return <h3 className="dialog_subtitle">Shipping logic</h3>
      }
      if(logicType.logic_type_id === 2){
        return <h3 className="dialog_subtitle">Purchase Order</h3>
      }
      if(logicType.logic_type_id === 3){
        return <h3 className="dialog_subtitle">Bulk & Tiered Discounts</h3>
      }
      if(logicType.logic_type_id === 4){
        return <h3 className="dialog_subtitle">Hide Variables</h3>
      }
      if(logicType.logic_type_id === 5){
        return <h3 className="dialog_subtitle">Build A Bundle</h3>
      }
      if(logicType.logic_type_id === 6){
        return <h3 className="dialog_subtitle">Custom</h3>
      }
    }

  }



  return (
      <>
        <Dialog 
          open={openEditor}
          onClose={() => setOpenEditor(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          PaperProps={{ sx: { borderRadius: "1.25rem", minWidth:"750px" } }}
        >

            
          <div className="DialogContainer">
            <div className="ModuleWrapper">
              <div className="boxContainerHeader">
                <div className="dialog_container_title">
                    <h2 className="dialog_title"><img src={ic_logic}/>
                      {Object.keys(logicType).length === 0 ? "Select Logic Type" : logic_id ? "Edit Logic" : "Add Logic"}
                    </h2>
                    
                    {getsubTitle()}
                </div>
              </div>
              {Object.keys(logicType).length === 0 ? (
                <div className="dialogFormContainer">
                  <LogicMenu setLogicType={setLogicType} />
                </div>
              ) : (
              <div className="dialogFormContainer">
                {Object.keys(logicType).length > 0 && logicType.logic_type_id === 1 &&
                  <ShippingLogic setLogicType={setLogicType} logicValues={logicValues} setLogicvalues={setLogicValues} />
                }
                
                {Object.keys(logicType).length > 0 && logicType.logic_type_id === 2 &&
                  <PurchaseOrder setLogicType={setLogicType} logicValues={logicValues} setLogicvalues={setLogicValues} />
                }

                {Object.keys(logicType).length > 0 && logicType.logic_type_id === 3 &&
                  <BulkDiscount setLogicType={setLogicType} logicValues={logicValues} setLogicvalues={setLogicValues} />
                }

                {Object.keys(logicType).length > 0 && logicType.logic_type_id === 4 &&
                  <HideVariables setLogicType={setLogicType} logicValues={logicValues} setLogicvalues={setLogicValues} />
                }

                {Object.keys(logicType).length > 0 && logicType.logic_type_id === 5 &&
                  <BuildBundle setLogicType={setLogicType} logicValues={logicValues} setLogicvalues={setLogicValues} />
                }

                {Object.keys(logicType).length > 0 && logicType.logic_type_id === 6 &&
                  <Custom setLogicType={setLogicType} logicValues={logicValues} setLogicvalues={setLogicValues} />
                }
              </div>
              )}
              


              
              </div>
          </div>

          <div className="dialogButtons">

            {Object.keys(logicType).length > 0 &&
              <Button
                  onClick={handleSubmit}
                  variant="contained"
                  className="button_1"
              >
                {logic_id ? "Edit Logic" : "Add Logic"}
              </Button>
            }

            <Button
                onClick={() => {
                  setOpenEditor(false)
                  setLogicType({})
                  setLogicValues({})
                }}
                variant="contained"
                color="secondary"
                className="button_secondary"
            >
              Cancel
            </Button>
            
            

            
          </div>

        </Dialog>
      </>
  );
}
