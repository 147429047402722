import { Button, IconButton, Menu, MenuItem, MenuList, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import axios from "axios";
import { ProjectConfig } from "Global";
import { ApiRequest } from "GlobalFunctions";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import { useSnackbar } from "notistack";
import AlertDialog from "elements/AlertDialog";
import TaxesEditor from "./TaxesEditor";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import 'assets/css/main.css'; // Asegúrate de tener las clases CSS definidas aquí

export default function TaxesList(props: any) {
    const { setLoading } = props;

    const [openEditor, setOpenEditor] = useState(false);
    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
    const [activeItem, setActiveItem] = useState<any>(null);
    const [openAlert, setOpenAlert] = useState(false);
    const [list, setList] = useState<any[]>([]);
    const [selectedType, setSelectedType] = useState<string>('product');
    const [selectedCountry, setSelectedCountry] = useState<number | null>(null);
    const [countries, setCountries] = useState<any[]>([]);
    const [activeCountry, setActiveCountry] = useState<any>(null);

    const { enqueueSnackbar } = useSnackbar();

    // Estado y función para controlar el acordeón, igual que en MainMenu
    const [expandedIndex, setExpandedIndex] = useState<number | false>(0);

    const handleAccordionChange = (index: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpandedIndex(isExpanded ? index : false); // Si es el acordeón abierto, se cierra; si no, se abre
    };

    useEffect(() => {
        setLoading(false);
        getCountries(); // Obtener la lista de países al cargar el componente
    }, []);

    useEffect(() => {
        loadList();
        
    }, [selectedCountry, selectedType]);

    /*=========================================================
    LOAD LIST
    =========================================================*/
    const loadList = async () => {
        setLoading(true);
        await ApiRequest({
            method: 'GET',
            url: '/taxes/list',
            query: {
                country_id: selectedCountry,
                tax_type: selectedType
            },
            setResponse: (response: any) => {
                setList(response.list);
                setLoading(false);

                if(!activeCountry){
                    setActiveCountry(response.selectedCountry);
                }
            }
        });
    };

    /*=========================================================
    GET COUNTRIES
    =========================================================*/
    const getCountries = async () => {
        await ApiRequest({
            url: '/countries/getCountries',
            method: 'GET',
            setResponse: (response: any) => {
                setCountries(response);
            }
        });
    };

    /*=========================================================
    HANDLE MENU OPEN
    =========================================================*/
    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, item: any) => {
        setMenuAnchor(event.currentTarget);
        setActiveItem(item);
    };

    /*=========================================================
    HANDLE MENU CLOSE
    =========================================================*/
    const handleMenuClose = () => {
        setMenuAnchor(null);
    };

    /*=========================================================
    HANDLE TYPE CHANGE
    =========================================================*/
    const handleTypeChange = (type: string, country_id: number) => {
        setSelectedType(type);
        setSelectedCountry(country_id);
        setActiveCountry(country_id);
    };

    /*=========================================================
    DELETE ITEM
    =========================================================*/
    const handleDelete = (state_id: number) => {
        setActiveItem(state_id);
        setOpenAlert(true);
    };

    const deleteItem = () => {
        const body = { state_id: activeItem };
        axios.post(ProjectConfig.api_url + "/v2/taxes/deleteItem", body)
            .then((response) => {
                if (response.data.status === "success") {
                    enqueueSnackbar('Item deleted successfully', { variant: 'success' });
                    loadList();
                }
            })
            .catch((err) => {
                console.log(err.data);
            })
            .then(() => {
                setOpenAlert(false);
            });
    };

    // Definir las categorías para el mapeo, igual que en MainMenu
    const categories = [
        { type: 'product', label: 'Product Based' },
        { type: 'service', label: 'Service Based' }
    ];

    return (
        <div className='ModuleContainer'>
            <div className="ModuleHeader">
                <div className="ModuleTitle">
                    <h1>Taxes</h1>
                </div>
                <div className="ModuleActions">

                </div>
            </div>
            
            <div className='ModuleContent ModuleFlexRow'>
                <aside className="ModuleAside">
                    {/* Acordeones utilizando las mismas clases y funciones que en MainMenu */}
                    {categories.map((category, index) => (
                        <Accordion 
                            key={index} 
                            className='menuAccordion' 
                            expanded={expandedIndex === index} 
                            onChange={handleAccordionChange(index)}
                        >
                            <AccordionSummary 
                                expandIcon={ <ExpandMoreIcon sx={{color: '#1D3B47'}} />}
                                className='menuAccordionItem'
                                sx={{
                                    backgroundColor: expandedIndex === index ? '#F6F6F7' : '#FFF',
                                    paddingRight: '5px !important'
                                }}
                            >
                                <div className='menuCategory'>{category.label}</div>
                            </AccordionSummary>

                            <AccordionDetails className='menuAccordionDetails'>
                                <div className='submenu_container'>
                                    <MenuList>
                                        {countries.map((country) => (
                                            <MenuItem
                                                key={country.country_id}
                                                onClick={() => handleTypeChange(category.type, country.country_id)}
                                                selected={country.country_id === activeCountry}
                                            >
                                                {country.country_name}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </aside>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Description</TableCell>
                            <TableCell>Country</TableCell>
                            <TableCell>Region</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Rate</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list.map((item, i) => (
                            <TableRow key={i}>
                                <TableCell>{item.description}</TableCell>
                                <TableCell>{item.country_name}</TableCell>
                                <TableCell>{item.state_name}</TableCell>
                                <TableCell>{item.type}</TableCell>
                                <TableCell>{item.tax}</TableCell>
                                <TableCell align="right">
                                    <IconButton aria-label="edit" color="primary" onClick={(event) => handleMenuOpen(event, item)}>
                                        <MoreHorizIcon />
                                    </IconButton>
                                    <Menu
                                        anchorEl={menuAnchor}
                                        open={Boolean(menuAnchor && activeItem && activeItem.state_id === item.state_id)}
                                        onClose={handleMenuClose}
                                    >
                                        <MenuItem onClick={() => {
                                            setOpenEditor(true);
                                            handleMenuClose();
                                        }}>
                                            <EditIcon fontSize="small" />
                                            Edit
                                        </MenuItem>
                                    </Menu>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>

            <TaxesEditor
                setLoading={setLoading}
                openEditor={openEditor}
                setOpenEditor={setOpenEditor}
                state_id={activeItem ? activeItem.state_id : null}
                loadList={loadList}
                tax_type={selectedType}
            />

            <AlertDialog
                open={openAlert}
                setOpen={setOpenAlert}
                subtitle="Are you sure you want to delete this item?"
                send={deleteItem}
            />
        </div>
    );
}
