import { Button, Dialog, TextField, InputLabel, Select, MenuItem, FormControl } from "@mui/material";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { ApiRequest } from "GlobalFunctions";
import ic_logic from 'icons/ic_logic.svg';

export default function ShippingEditor(props: any) {
  const { setLoading, openEditor, setOpenEditor, shipping_id, loadList } = props;
  const { enqueueSnackbar } = useSnackbar();

  const defaultFormValues = {
    shipping_id: shipping_id,
    shipping_name: "",
    shipping_price: "",
    shipping_description: "",
    estimated_time: {
      min: "",
      max: "",
      unit: "Calendar days",
    },
  };

  const [formValues, setFormValues] = useState<any>(defaultFormValues);

  useEffect(() => {
    if (shipping_id) {
      getDetails();
    } else {
      setFormValues(defaultFormValues);
    }
  }, [openEditor]);

  const getDetails = async () => {
    await ApiRequest({
      method: 'GET',
      url: '/shipping/details',
      query: {
        shipping_id: shipping_id,
      },
      setResponse: (response: any) => {
        setFormValues({
          ...response,
          estimated_time: {
            ...response.estimated_time,
            min: response.estimated_time?.min || "",
            max: response.estimated_time?.max || "",
            unit: response.estimated_time?.unit || "Calendar days",
          }
        });
      }
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    await ApiRequest({
      method: 'POST',
      url: '/shipping/addedit',
      body: formValues,
      setResponse: (response: any) => {
        if (response.status === "success") {
          enqueueSnackbar('Success', { variant: 'success' });
          setOpenEditor(false);
          loadList();
        } else {
          enqueueSnackbar(response.message, { variant: 'error' });
        }
      }
    });
    setLoading(false);
  };

  const handleClose = () => {
    setOpenEditor(false);
    setFormValues(defaultFormValues); // Reiniciar el formulario al cerrar
  };

  return (
    <>
      <Dialog
        open={openEditor}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        PaperProps={{ sx: { borderRadius: "20px", minWidth: "750px" } }}
      >
        <div className="DialogContainer">
          <div className="dialog_container_title">
            <h2 className="dialog_title"><img src={ic_logic} alt="Logic Icon" />
              {shipping_id ? "Edit Shipping Method" : "Add Shipping Method"}
            </h2>
          </div>

          <div className="dialogFormContainer">
            <div className="input_group">
              <TextField
                fullWidth
                label="Shipping Name"
                variant="outlined"
                value={formValues.shipping_name}
                onChange={(e) => setFormValues({ ...formValues, shipping_name: e.target.value })}
              />
              <TextField
                fullWidth
                label="Price"
                variant="outlined"
                type="number"
                value={formValues.shipping_price}
                onChange={(e) => setFormValues({ ...formValues, shipping_price: e.target.value })}
              />
              <TextField
                fullWidth
                label="Description"
                variant="outlined"
                value={formValues.shipping_description}
                onChange={(e) => setFormValues({ ...formValues, shipping_description: e.target.value })}
              />

              <InputLabel>Estimated Time</InputLabel>
              <div className="input_row">
                <TextField
                  label="Min"
                  variant="outlined"
                  type="number"
                  value={formValues.estimated_time.min}
                  onChange={(e) => setFormValues({
                    ...formValues,
                    estimated_time: { ...formValues.estimated_time, min: e.target.value }
                  })}
                />
                <TextField
                  label="Max"
                  variant="outlined"
                  type="number"
                  value={formValues.estimated_time.max}
                  onChange={(e) => setFormValues({
                    ...formValues,
                    estimated_time: { ...formValues.estimated_time, max: e.target.value }
                  })}
                />
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Unit</InputLabel>
                  <Select
                    label="Unit"
                    value={formValues.estimated_time.unit}
                    onChange={(e) => setFormValues({
                      ...formValues,
                      estimated_time: { ...formValues.estimated_time, unit: e.target.value }
                    })}
                  >
                    <MenuItem value="Calendar days">Calendar Days</MenuItem>
                    <MenuItem value="Business days">Business Days</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </div>

        <div className="dialogButtons">
          <Button
            onClick={handleSubmit}
            variant="contained"
            className="button_1"
          >
            {shipping_id ? "Edit Shipping Method" : "Add Shipping Method"}
          </Button>

          <Button
            onClick={handleClose}
            variant="contained"
            color="secondary"
            className="button_secondary"
          >
            Cancel
          </Button>
        </div>
      </Dialog>
    </>
  );
}
