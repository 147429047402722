import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ProjectConfig } from "Global";
import Box from "elements/Box";
import { Button, Divider, FormControl, FormControlLabel, IconButton, Menu, MenuItem, Radio, RadioGroup } from "@mui/material";
import Indicators from "./components/indicators/Indicators";
import GoalProgress from "./components/GoalProgress";
import TopAccounts from "./components/TopAccounts";
import PlanChart from "./components/PlanChart";
import BoxIndicator from "./components/indicators/BoxIndicator";
import ic_tune from 'icons/ic_tune.svg';

export default function Home(props: any) {
  const { setLoading } = props;


  /*=============================================
  GET DASHBOARD DATA
  =============================================*/
  const [dashboardData, setDashboardData] = useState({
    indicators: [],
    goalProgress: [],
    topAccounts: [],
    planChart: {
      labels: [],
      datasets: [],
      totals: []
    }
  });

  /*=============================================
  FILTERS 
  =============================================*/
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);


  
  const [filter, setFilter] = useState("Last 90 Days");

  /*=============================================
  GET DASHBOARD DATA
  =============================================*/
  const getDashboardData = async () => {
    setLoading(true);

    axios.get(ProjectConfig.api_url+"/dashboard",
    {
      headers: {
        'ltkn': localStorage.getItem('ltkn')
      },
      params: {
        filter
      }
    })
    .then((response) => {
        console.log("dashboard data ",response.data); 
        setDashboardData(response.data);
    })
    .catch((err) => {
      console.log(err.response.data);
    })
    .finally(() => {
      setLoading(false);
    })
  };


  
  useEffect(() => {
    getDashboardData();
  }, [filter]);





  return (
    <div id="Dashboard" className="ModuleContainer">
      <div className="ModuleWrapper">
        <div className="ComponentHeader">
          <div className="ComponentTitle">
            <h1>Report</h1>
          </div>

          <div className="ComponentButtons">

          <Button
            endIcon={<img src={ic_tune} alt="Filter" />}
            onClick={(e)=> {
              setAnchorEl(e.currentTarget);
            }}
            style={{ color: '#424242', padding: '5px 20px', fontWeight: '400 !important' }}
            className='filtersButton'
          >
            Filter
          </Button>
          

            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              className="filtersMenu"
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)} 
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              PaperProps={{
                style: {
                  maxHeight: 48 * 5,
                },
              }}
            >
              <h3 className="filtersTitle">Date Range</h3>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                >
                  {[
                    "Today",
                    "Yesterday",
                    "Last 24 Hours",
                    "Last 7 Days",
                    "Week To Date",
                    "Last 30 Days",
                    "Last Month",
                    "Month To Date",
                    "Last 90 Days",
                    "Quarter To Date",
                    "Last 12 Months",
                    "Year To Date",
                    "All Time"
                  ].map((value, index) => (
                    <MenuItem 
                      className="menuItem" 
                      value={value} 
                      key={index} 
                      onClick={() => {
                        setAnchorEl(null);
                        setFilter(value)
                      }}

                    >
                      <FormControlLabel value={value} control={<Radio size="small" />} label={value} checked={filter === value} />
                    </MenuItem>
                  ))}
                </RadioGroup>
              </FormControl>
            </Menu>
          </div>
        </div>

        <div id="DashboardIn">
          <div id="dashboardIndicators">
            {dashboardData.indicators.map((indicator: any, i: number) => (
              <BoxIndicator 
                key={i}
                title={indicator.title}
                indicator={indicator.value}
                percentageLabel={indicator.percentage}
                icon="up"
              />
            ))}
          </div>
          {/* <GoalProgress value={dashboardData.goalProgress} /> */}
          <PlanChart data={dashboardData.planChart} />
          <Divider color="#7963F" sx={{ borderBottomWidth: 2 }} />
          <TopAccounts />
        </div>
      </div>
    </div>
  );
}
