import { Avatar, Button, Divider, ListItemText, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { PiUserCircleLight } from "react-icons/pi";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Link, useNavigate } from "react-router-dom";

export default function TopMenuAdmin(props: any) {
    const { setLoading, data } = props;
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const logout = async () => {
        localStorage.removeItem('ltkn');
        localStorage.removeItem('store_id');
        localStorage.removeItem('user_id');
        window.location.href = '/';
    };

    return (
        <>
            <Button
                id="site-select"
                variant="text"
                className='sites_dropdown'
                onClick={(event) => {
                    setOpen(true);
                    setAnchorEl(event.currentTarget);
                }}
                sx={{ border: "none", textTransform: "none" }}
                endIcon={<KeyboardArrowDownIcon />}
            >
                <Avatar sx={{ backgroundColor: "#428EFF" }} src={data.logo}>
                    <PiUserCircleLight size={30} />
                </Avatar>
                <div className='active_site_name'>{data.name} {data.lastname}</div>
            </Button>

            <Menu 
                anchorEl={anchorEl}
                open={open}
                onClose={() => setOpen(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{ 
                    borderRadius: '20px',
                    marginTop: '40px',
                    padding: '0px', // Quitar el padding para manejarlo dentro del contenido
                }}
                className='sites_dropdown_menu'
            >
                <div style={{ minWidth: '150px', padding: '20px' }}>

                    <MenuItem 
                        onClick={logout}
                        className='logout'
                    >
                        <ListItemText>Logout</ListItemText>
                    </MenuItem>
                </div>
            </Menu>
        </>
    );
}
